<template>
  <div>
    <div class="container" id="section-to-print" v-if="orders.length != 0">
      <br />
      <div class="header">
        <center><h2>ข้อมูลการขาย</h2></center>
        <br /><br />
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <center>
              <img src="@/assets/takeco.webp" alt="takeco" width="20%" /><br />
              <p>TAKECO ENGINERRING THAILAND</p>
            </center>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div>
              <br />
              <v-row>
                <v-col cols="12" md="12" sm="12"
                  ><center>
                    <div>
                      <h4 style="display: inline">วันที่ :</h4>
                      <p style="display: inline">
                        {{ dayjs(orders[0].created).format("DD/MM/BBBB") }}
                      </p>
                    </div>
                  </center>
                </v-col>

                <v-col cols="12" md="12" sm="12">
                  <center>
                    <div>
                      <h4>พนักงานขาย :</h4>
                      <p>
                        {{ orders[0].firstname + " " + orders[0].lastname }}
                      </p>
                    </div>
                  </center>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <br />
      <div class="customer">
        <h3>ข้อมูลลูกค้า</h3>
        <br />
        <v-row class="ml-4"
          ><v-col cols="12" sm="6" md="6">
            <h4>ชื่อ :</h4>
            <p>{{ orders[0].customer_name }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>บริษัท :</h4>
            <p>{{ orders[0].company_name }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>เบอร์ติดต่อ :</h4>
            <p>{{ orders[0].tel }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>ตำแหน่ง :</h4>
            <p>{{ orders[0].position }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>ช่องทาง :</h4>
            <p>{{ orders[0].CHANNEL }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>ความต้องการ :</h4>
            <p>{{ orders[0].demand }}</p>
          </v-col>
        </v-row>
      </div>
      <div class="requirement">
        <h3>ความต้องการของลูกค้า</h3>
        <div class="Cusreq">
          <div v-for="item in type" :key="item.id">
            <v-checkbox
              v-model="form.type"
              readonly
              :label="item.name"
              :value="item.id"
            ></v-checkbox>
          </div>
        </div>
        <h3>ข้อมูลลูกค้า</h3>
        <br />
        <v-row class="ml-4">
          <v-col cols="12" sm="6" md="6">
            <h4>ชิ้นงาน :</h4>
            <p>{{ orders[0].pipe }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>ขนาด :</h4>
            <p>{{ orders[0].size }}</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h4>วัสดุ :</h4>
            <p>{{ orders[0].material }}</p>
          </v-col>
        </v-row>
      </div>
      <div class="detail">
        <h3>รายละเอียด</h3>
        <br />
        <h4 class="ml-3">สินค้า : {{ orders[0].product }}</h4>

        <div class="ml-3">
          <br />
          <v-row>
            <v-col
              v-for="(item, i) in products"
              :key="i"
              cols="12"
              md="3"
              sm="4"
            >
              <v-card class="mx-auto" max-width="400">
                <center>
                  <v-img
                    class="white--text align-end"
                    height="250px"
                    width="80%"
                    :src="item.image | imageUrl"
                  >
                  </v-img>
                </center>

                <v-card-title> {{ item.name }} </v-card-title>
                <v-card-text class="text--primary">
                  <div>{{ item.detail | truncate(100) }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="detail-text">
          <p>{{ orders[0].detail }}</p>
        </div>

        <v-simple-table class="mr-4 ml-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">date</th>
                <th class="text-left">time</th>
                <th class="text-left">status</th>
                <th class="text-left">detail</th>
                <th class="text-left">file</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in status" :key="item.name">
                <td>{{ dayjs(item.date).format("DD-MM-BBBB") }}</td>
                <td>{{ dayjs(item.date).format("H:m:s") }}</td>
                <td>
                  <v-chip :color="item.color" text-color="white">
                    {{ item.status }}
                  </v-chip>
                </td>
                <td>{{ item.detail }}</td>
                <td>
                  <div v-if="item.file != null">
                    <v-chip
                      class="mr-1 mb-1"
                      close
                      color="primary"
                      text-color="white"
                      small
                      v-for="data in item.file.split(',')"
                      :key="data.index"
                      close-icon="mdi-download-circle"
                      @click:close="download(data)"
                    >
                      {{ data }}
                    </v-chip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <br /><br />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import order from "@/services/orders";
import setting from "@/services/setting";
import product from "@/services/product";
import { orderUrl } from "@/services/constants";
export default {
  data() {
    return {
      id: this.$route.params.id,
      orders: [],
      status: [],
      products: [],
      type: [],
      form: {
        type: [],
      },
    };
  },
  mounted() {
    this.get_order();
    this.get_status();
    this.get_product();
    this.get_type();
  },
  methods: {
    async get_order() {
      let result = await order.get_orderByid(this.$route.params.id);

      if (result.data.result.length != 0) {
        this.orders = result.data.result;

        this.orders[0].type_id.split(",").forEach((element) => {
          this.form.type.push(parseInt(element));
        });
      } else if (result.data.msg == "error") {
        alert(result.data.result);
      }
    },
    async get_status() {
      let result = await order.get_orderstatus(this.$route.params.id);
      if (result.data.msg == "ok") {
        this.status = result.data.result;
      }
    },
    async get_product() {
      let result = await product.get_productByOrder(this.$route.params.id);
      if (result.data.result.length != 0) {
        this.products = result.data.result;
      } else if (result.data.msg == "error") {
        alert(result.data.result);
      }
    },
    async get_type() {
      let result = await setting.get_type();
      this.type = result.data.result;
    },

    download(file) {
      axios
        .get(`${orderUrl}/download/file/${file}`, { responseType: "blob" })
        .then((response) => {
          console.log(response);
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  display: inline;
}
p {
  display: inline;
}

.header {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  padding: 20px;
}
.customer {
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 10px;
}

.requirement {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 10px;
}

.detail {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 10px;
}

.detail-text {
  box-shadow: rgba(20, 20, 20, 0.774) 0px 0px 0px 2px;
  padding: 10px;
  margin: 30px;
  width: 80vh;
  border-radius: 5px;
}

.Cusreq {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.topcorner {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .topcorner {
    padding: 0;
    position: relative;
    top: 0;
    right: 0;
  }

  .detail-text {
    box-shadow: rgb(20 20 20 / 77%) 0px 0px 0px 2px;
    padding: 10px;
    margin: 30px;
    width: auto;
    border-radius: 5px;
  }

  .name_card:hover {
    transform: scale(
      -2
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media print {
  @page {
    size: A4 portrait;
  }
  .detail * {
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0px;
  }

  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.name_card {
  width: 100%;
  height: auto;
}

.name_card:hover {
  transform: scale(
    1.6
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
