<template>
  <div class="CSTM">
    <br />
    <!-- Header -->
    <v-container class="ctn">
      <div class="headerpage">
        <!-- <img src="@/assets/takeco.webp" alt="" srcset="" /> -->
        <h1>TAKECO ENGINEERING THAILAND</h1>
        <div>
          <p>
            <v-select
              :items="Seller"
              v-model="Form.user_id"
              label="พนักงานขาย"
              item-text="firstname"
              item-value="id"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.firstname }} {{ data.item.lastname }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.firstname }} {{ data.item.lastname }}
              </template>
            </v-select>
          </p>
          <p>วันที่ : {{ get_date }}</p>
        </div>
      </div>
    </v-container>
    <!-- ข้อมูลลูกค้า -->
    <v-container class="TextProb">
      <h2>CUSTOMER'S CONTACT</h2>
      <br />
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" md="5">
              <v-autocomplete
                v-model="Company_select"
                :items="company"
                item-text="name"
                item-value="id"
                label="บริษัท"
                append-icon="mdi-home-plus"
                @click:append="set_company"
                @change="get_customer"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="1">
              <v-checkbox
                label="บุคคล"
                v-model="checkbox"
                @change="get_usernull"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="5">
              <v-autocomplete
                v-model="Form.customer_id"
                :items="Customer"
                item-text="name"
                item-value="id"
                label="ชื่อ"
                append-icon="mdi-account-plus"
                @click:append="set_customer"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="Form.Phone"
                label="เบอร์โทร"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="Form.channel_id"
                :items="Contact_from"
                item-text="name"
                item-value="id"
                label="ช่องทางการขาย"
                persistent-hint
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="Form.customer_status"
                :items="status_sell"
                item-text="name"
                item-value="id"
                label="สถานะ"
              ></v-select>
            </v-col>
          </v-row>
          <!-- Target -->
          <v-row v-if="Form.customer_status == 5">
            <v-col cols="12" md="7" sm="12">
              <center>
                <h2>
                  {{
                    Form.price
                      | currency("฿", 2, {
                        spaceBetweenAmountAndSymbol: true,
                      })
                  }}
                </h2>
              </center>
            </v-col>
            <v-col cols="12" md="5" sm="12">
              <v-text-field
                label="ยอดขาย"
                hint="กรอกเฉพาะตัวเลยเท่านั้น"
                persistent-hint
                v-model="Form.price"
                outlined
                prefix="฿"
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <div class="Cusreq">
            <v-radio-group v-model="Form.demand" row>
              <v-radio
                v-for="item in Status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </div>
          <br />
        </v-container>
      </v-form>
    </v-container>
    <br />
    <!-- ความต้องการลูกค้า -->
    <v-container class="bodymain">
      <h2>CUSTOMER'S REQUIREMENT</h2>
      <div class="Cusreq">
        <div v-for="item in type" :key="item.id">
          <v-checkbox
            v-model="Form.requirement"
            :label="item.name"
            :value="item.id"
          ></v-checkbox>
        </div>
      </div>
      <!-- Pipe -->
      <br />
      <h2>CUSTOMER'S INFORMATION</h2>
      <div class="CsInfo">
        <div class="pipeinfo">
          <div v-for="item in pipe" :key="item.id">
            <v-checkbox
              v-model="Form.pipe"
              :label="item.name"
              :value="item.id"
            ></v-checkbox>
          </div>
        </div>
        <div>
          <v-row
            ><v-col cols="12" md="6">
              <v-text-field v-model="Form.Size" label="Size"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="Form.Material"
                label="Material"
              ></v-text-field> </v-col
          ></v-row>
        </div>
      </div>
    </v-container>
    <br />
    <!-- เครื่องจักร-->
    <v-container class="TextProb">
      <h2>PRODUCT / สินค้า</h2>
      <br />
      <div>
        <v-row>
          <v-col cols="12" md="2" sm="4">
            <v-card class="mx-auto" max-width="300" tile>
              <v-list shaped>
                <v-subheader>หมวดหมู่</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in category"
                    :key="i"
                    class="tile"
                    @click="get_product_category(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <br />
            <v-card class="mx-auto" max-width="300" tile
              ><div class="text-center">
                <p>สินค้าที่เลือก</p>
                <v-chip
                  class="ma-2"
                  close
                  small
                  color="green"
                  outlined
                  v-for="(item, i) in Form.product"
                  :key="i"
                  @click:close="removeItem(item)"
                >
                  {{ item.name }}
                </v-chip>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="10" sm="8"
            ><div class="myBox">
              <v-row>
                <v-col
                  v-for="(item, i) in products"
                  :key="i"
                  cols="12"
                  md="2"
                  sm="6"
                >
                  <v-card class="mx-auto" max-width="400">
                    <v-img
                      class="white--text align-end"
                      height="150px"
                      width="100%"
                      :src="item.image | imageUrl"
                    >
                    </v-img>
                    <v-card-title class="title-product">
                      {{ item.name }}
                    </v-card-title>
                    <v-card-text class="text--primary">
                      <!-- <div>{{ item.detail | truncate(80) }}</div> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange" text @click="add_product(item)">
                        เลือก
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <br />
      </div>

      <br />
      <h2>Detail / รายละเอียด</h2>
      <br />
      <v-textarea
        background-color="light-blue lighten-4"
        color="white"
        filled
        v-model="Form.detail"
        name="input-7-4"
        label="ข้อความ"
      ></v-textarea>

      <v-snackbar
        v-model="alert"
        fixed
        right
        shaped
        top
        :color="aleart_type"
        timeout="3000"
      >
        {{ aleart_text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="alert = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar fixed right shaped color="green" top v-model="snackbar">
        <v-progress-linear
          v-show="progress"
          v-model="percentCompleted"
          height="25"
          color="white"
        >
          <strong>{{ Math.ceil(percentCompleted) }}%</strong>
        </v-progress-linear>
      </v-snackbar>

      <div>
        <v-file-input
          v-model="files"
          color="deep-purple accent-4"
          counter
          accept="*"
          label="File input"
          multiple
          placeholder="Select your files"
          prepend-icon="mdi-paperclip"
          outlined
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
      </div>
      <br />
      <div class="text-center">
        <v-btn rounded color="primary" large dark @click="Adddata()">
          บันทึกข้อมูล
        </v-btn>
      </div>
      <br /><br />
    </v-container>
    <br />

    <v-dialog v-model="dialog_customer" width="500">
      <v-card>
        <v-card-title class="text-h5 blue lighten-2">
          เพิ่มลูกค้า
        </v-card-title>
        <v-card-text><Addcustomer @dialog="set_userdailog" /> </v-card-text>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog_customer = false"> I accept </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_company" width="800">
      <v-card>
        <v-card-title class="text-h5 blue lighten-2">
          เพิ่มบริษัท
        </v-card-title>
        <br />
        <v-card-text><Addcompany @dialog="set_dailog" /> </v-card-text>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog_customer = false"> I accept </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <br /><br />
  </div>
</template>

<script>
import dayjs from "dayjs";
import setting from "@/services/setting";
import company from "@/services/company";
import customer from "@/services/customers";
import Addcompany from "@/components/company/add_company.vue";
import Addcustomer from "@/components/customer/add_customer.vue";
import product from "@/services/product";
import order from "@/services/orders";
import users from "@/services/users";
export default {
  name: "new_data",
  components: {
    Addcompany,
    Addcustomer,
  },
  data() {
    return {
      selectedItem: null,
      dialog_customer: false,
      dialog_company: false,
      checkbox: false,
      Company_select: "",
      status_sell: [],
      files: [],
      progress: false,
      snackbar: false,
      percentCompleted: 0,
      overlay: false,
      alert: false,
      aleart_text: "",
      aleart_type: "",
      Form: {
        user_id: "",
        requirement: [],
        pipe: [],
        product: [],
        detail: "",
        customer_id: "",
        Phone: "",
        price: 0,
        demand: "",
        Size: "",
        Material: "",
        channel_id: "",
        customer_status: "",
      },
      validate: [
        "user_id",
        "detail",
        "customer_id",
        "demand",
        "Size",
        "Material",
        "channel_id",
        "customer_status",
      ],
      Product: [
        "TIGER",
        "VELOCEX",
        "HYDMECH",
        "SHARK",
        "COBRA",
        "PNK",
        "HSS",
        "BAND SAW",
        "CARBIDE CERMET",
        "CARBIDE TCT",
        "Etc.",
      ],
      arrvalidate: ["requirement", "pipe", "product"],
      Contact_from: [],
      company: [],
      Seller: [],
      Customer: [],
      type: [],
      pipe: [],
      Status: [],
      category: [],
      products: [],
      category_id: "",
    };
  },
  computed: {
    get_date() {
      return dayjs().format("DD/MM/YYYY");
    },
  },
  watch: {
    "Form.customer_id"(newValue) {
      let result = this.Customer.filter((customer) => customer.id == newValue);
      this.Form.Phone = result[0].tel;
    },
  },
  mounted() {
    this.getChannel();
    this.getStatus();
    this.getDemand();
    this.getsales();
    this.getCompany();
    this.get_type();
    this.get_pipe();
    this.get_category();
  },
  methods: {
    removeItem(item) {
      this.Form.product = this.Form.product.filter((x) => x.id !== item.id);
    },
    async add_product(item) {
      if (this.Form.product.length == 0) {
        this.Form.product.push(item);
      } else {
        if (
          !this.Form.product.some((arrVal) => {
            return item.id === arrVal.id;
          })
        ) {
          this.Form.product.push(item);
        }
      }
    },
    async get_product_category(item) {
      if (this.products.length == 0) {
        this.category_id = item.id;
        let result = await product.get_productBycategody(item.id);
        this.products = result.data.result;
      } else {
        if (this.category_id == item.id) {
          this.products = [];
          this.category_id = "";
        } else {
          this.category_id = item.id;
          let result = await product.get_productBycategody(item.id);
          this.products = result.data.result;
        }
      }
    },
    set_customer() {
      this.dialog_customer = true;
    },
    set_company() {
      this.dialog_company = true;
    },
    set_dailog(data) {
      this.dialog_company = data;
      this.getCompany();
    },

    set_userdailog(data) {
      this.dialog_customer = data;
      if (this.Company_select !== "") {
        this.get_customer();
      }
    },
    // **************************************************** Variable
    async get_category() {
      let result = await product.get_category();
      this.category = result.data.result;
    },
    async get_usernull() {
      let result = await setting.get_usernull();
      if (this.checkbox) {
        this.Customer = [];
        this.Customer = result.data.result;
      } else {
        if (this.Company_select !== "") {
          this.get_customer();
        } else {
          this.Customer = [];
        }
      }
    },
    async get_customer() {
      this.Customer = [];
      let result = await customer.get_customer_company(this.Company_select);
      this.Customer = result.data.result;
    },
    async get_type() {
      let result = await setting.get_type();
      this.type = result.data.result;
    },
    async get_pipe() {
      let result = await setting.get_pipe();
      this.pipe = result.data.result;
    },
    async getCompany() {
      this.company = [];
      let result = await company.get_company();
      this.company = result.data.result;
    },
    async getsales() {
      let result = await users.get_userOfmanager(localStorage.getItem("id"));
      this.Seller = result.data.result;
    },
    async getChannel() {
      let result = await setting.getchannel();
      this.Contact_from = result.data.channel;
    },
    async getStatus() {
      let result = await setting.get_status();

      this.status_sell = result.data.status;
    },
    async getDemand() {
      let result = await setting.get_demand();

      this.Status = result.data.demand;
    },
    // **************************************************** Variable

    Validate() {
      this.overlay = true;
      let val = true;
      this.arrvalidate.forEach((el) => {
        if (this.Form[el].length > 0) {
          this.validate.forEach((element) => {
            if (this.Form[element] == "") {
              this.overlay = false;
              this.aleart_text = "กรุณากรอกข้อมูลให้ครบ";
              this.alert = true;
              this.aleart_type = "red";
              setTimeout(() => (this.alert = false), 3000);
              val = false;
            }
          });
        } else {
          this.overlay = false;
          this.aleart_text = "กรุณากรอกข้อมูลให้ครบ";
          this.alert = true;
          this.aleart_type = "red";
          setTimeout(() => (this.alert = false), 3000);
          val = false;
        }
      });
      return val;
    },
    async Adddata() {
      if (this.Validate()) {
        let formData = new FormData();
        let product = [];
        this.Form.product.forEach((element) => {
          product.push(element.id);
        });

        formData.append("user_id", this.Form.user_id);
        formData.append("requirement", this.Form.requirement);
        formData.append("pipe", this.Form.pipe);
        formData.append("product", product);
        formData.append("detail", this.Form.detail);
        formData.append("customer_id", this.Form.customer_id);
        formData.append("demand", this.Form.demand);
        formData.append("Size", this.Form.Size);
        formData.append("pirce", this.Form.price);
        formData.append("Material", this.Form.Material);
        formData.append("channel_id", this.Form.channel_id);
        formData.append("customer_status", this.Form.customer_status);
        if (this.files.length !== 0) {
          for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i];

            formData.append("files[" + i + "]", file);
          }
          // formData.append("files[]", this.files);
        }
        let result = await order.add_order(formData);
        console.log(result);
        if (result.data.msg == "ok") {
          this.overlay = false;
          this.$swal({
            icon: "success",
            title: "บันทึกสำเร็จ",
            timer: 2000,
            timerProgressBar: true,
          });
          this.clear_data();
        }
      }
    },
    clear_data() {
      window.scrollTo(0, 0);
      this.files = [];
      this.products = [];
      this.category_id = "";
      this.Form = {
        user_id: "",
        requirement: [],
        pipe: [],
        product: [],
        detail: "",
        customer_id: "",
        Phone: "",
        price: 0,
        demand: "",
        Size: "",
        Material: "",
        contact_from: "",
        customer_status: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title-product {
  font-size: 15px;
  font-weight: bold;
}
.myBox {
  border: none;
  padding: 5px;
  width: 100%;
  height: 500px;
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border: 1px solid #1793c5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1793c5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4c5cd0;
}

.v-list-item-group .v-list-item--active {
  color: #fff !important;
  font-weight: bold;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  opacity: 0.7;
}

.tile {
  color: rgb(0, 0, 0) t;
}
.tile:hover {
  color: #fff !important;
  background: rgb(69, 144, 235);
}
.tile:active {
  color: rgb(0, 0, 0) !important;
  background: #04beec;
}
.v-list-item-group .v-list-item--active {
  color: #db4444 !important;
  font-weight: bold;
}

.product {
  width: 300;
  height: 300;
  background-color: rgb(251, 251, 251);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.prod {
  gap: 10px;
  padding-left: 50px;
}

.pipeinfo {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.aleart_input {
  width: 400px;
}
.CSTM {
  padding: 30px;
  background: linear-gradient(
    60deg,
    rgb(98, 69, 212) 0%,
    rgba(0, 172, 193, 1) 100%
  );
}
.TextProb {
  background: rgb(250, 250, 250);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.CsInfo {
  padding-left: 30px;
  padding-right: 30px;
}
.Cusreq {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.bodymain {
  background: rgb(250, 250, 250);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.ctn {
  border-radius: 10px;
  background: rgb(255, 255, 255);

  margin-bottom: 30px;
}
.headerpage {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  h1 {
    margin-top: 15px;
  }
  img {
    width: 15%;
  }
}

@media only screen and (max-width: 600px) {
  .headerpage {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    h1 {
      margin-top: 15px;
      font-size: 18px;
    }
    img {
      width: 40%;
    }
  }
}

.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
