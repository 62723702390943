import axios from "axios";
import {
  apiUrl,
  NOT_CONNECT_NETWORK,
  NETWORK_CONNECTION_MESSAGE,
} from "@/services/constants";
// import router from "@/router";
import store from "@/store/index";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = {
        Authorization: "Bearer" + " " + userToken,
        "Content-Type": "multipart/form-data",
      };
    }
    config.url = apiUrl + "/" + config.url;
  }
  config.timeout = 10000; // 10 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch({
      type: "openAlert",
      text: error.response.data.msg,
      color: "red",
    });
    // console.log(error.response.data.msg);
    // router.push("/");
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    }

    return Promise.reject(error);
  }
);

export default axios;
