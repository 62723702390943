<template>
  <div class="container">
    <div class="profile">
      <v-card class="overflow-hidden" color="grey lighten-5">
        <v-toolbar flat color="grey lighten-2">
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light">
            User Profile
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-1"
            fab
            small
            @click="isEditing = !isEditing"
          >
            <v-icon v-if="isEditing"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <div class="container mt-5">
                <div class="row d-flex justify-content-center">
                  <div class="col-md-7">
                    <div class="card p-3 py-4">
                      <div class="text-center">
                        <img
                          v-if="user.img == null"
                          src="@/assets/avatar.png"
                          width="100"
                          class="rounded-circle"
                        />
                        <img
                          v-else
                          :src="user.img | profileUrl"
                          width="100"
                          class="rounded-circle"
                        />
                      </div>

                      <my-upload
                        field="img"
                        @crop-upload-success="cropUploadSuccess"
                        @crop-upload-fail="cropUploadFail"
                        v-model="show"
                        :width="300"
                        :height="300"
                        langType="th"
                        :url="upload_url"
                        :params="params"
                        :headers="headers"
                        img-format="png"
                      ></my-upload>

                      <div class="text-center mt-3">
                        <h5 class="mt-2 mb-0">
                          {{ user.firstname }} {{ user.lastname }}
                        </h5>
                        <span>{{ user.position }}</span>

                        <!-- <div class="px-4 mt-1">
                          <v-row>
                            <v-col cols="12" md="6" sm="12" >
                              <h4></h4>
                            </v-col>
                          </v-row>
                          <p class="fonts">
                            {{ user }}
                          </p>
                        </div> -->

                        <ul class="social-list">
                          <li><i class="fa fa-facebook"></i></li>
                          <li><i class="fa fa-dribbble"></i></li>
                          <li><i class="fa fa-instagram"></i></li>
                          <li><i class="fa fa-linkedin"></i></li>
                          <li><i class="fa fa-google"></i></li>
                        </ul>

                        <div class="buttons">
                          <button class="btn btn-outline-primary px-4">
                            Message
                          </button>
                          <button
                            class="btn btn-primary px-4 ms-3"
                            @click="toggleShow"
                          >
                            เปลี่ยนรูป
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" sm="12"
              ><v-card-text>
                <v-text-field
                  :disabled="!isEditing"
                  color="white"
                  label="ชื่อ"
                  v-model="user.firstname"
                ></v-text-field>
                <v-text-field
                  :disabled="!isEditing"
                  color="white"
                  label="นามสกุล"
                  v-model="user.lastname"
                ></v-text-field>
                <v-text-field
                  :disabled="true"
                  color="white"
                  label="สถานะ"
                  v-model="user.rule_name"
                ></v-text-field>
                <v-text-field
                  :disabled="!isEditing"
                  color="white"
                  label="ตำแหน่ง"
                  v-model="user.position"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!isEditing" color="success" @click="save">
            Save
          </v-btn>
        </v-card-actions>
        <v-snackbar v-model="hasSaved" :timeout="2000" absolute bottom left>
          Your profile has been updated
        </v-snackbar>
      </v-card>
    </div>
  </div>
</template>

<script>
import users from "@/services/users";
import myUpload from "vue-image-crop-upload/upload-2.vue";

export default {
  data() {
    return {
      hasSaved: false,
      isEditing: null,
      model: null,
      user: {},
      show: false,
      params: {
        uid: localStorage.getItem("id"),
      },
      headers: {
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
    };
  },
  components: {
    "my-upload": myUpload,
  },
  computed: {
    upload_url: () => {
      return process.env.VUE_APP_API_URL + "/porfile_img";
    },
  },

  mounted() {
    this.getProfile();
  },

  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    cropUploadSuccess() {
      this.show = false;
      this.getProfile();
    },
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },

    async getProfile() {
      let result = await users.get_userByid(localStorage.getItem("id"));
      if (result.status == 200) {
        this.user = result.data.result[0];
      }
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.abbr.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    save() {
      this.isEditing = !this.isEditing;
      this.hasSaved = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  margin: 20px;
}
body {
  background: #eee;
}

.card {
  border: none;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.card:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #8e24aa;
  transform: scaleY(0);
  transition: all 0.5s;
  transform-origin: bottom;
}

// .card:hover::after {
//   transform: scaleY(1);
// }

.fonts {
  font-size: 13px;
}

.v-application .d-flex {
  display: flex !important;
  justify-content: center;
}

.social-list {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
}

.social-list li {
  padding: 10px;
  color: #8e24aa;
  font-size: 19px;
}

.buttons button:nth-child(1) {
  border: 1px solid #8e24aa !important;
  color: #8e24aa;
  height: 40px;
}

.buttons button:nth-child(1):hover {
  border: 1px solid #8e24aa !important;
  color: #fff;
  height: 40px;
  background-color: #8e24aa;
}

.buttons button:nth-child(2) {
  border: 1px solid #8e24aa !important;
  background-color: #8e24aa;
  color: #fff;
  height: 40px;
}
</style>
