<template>
  <div class="background-view">
    <div class="container">
      <h1 class="view-title">รายการบันทึก</h1>
      <br />
      <v-card>
        <v-card-title>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="เลือกวันที่"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :picker-date.sync="pickerDate"
              locale="th-th"
              @click:month="get_date"
              :events="arrayEvents"
              event-color="green lighten-1"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> ปิด </v-btn>
              <v-btn text color="primary" @click="date = ''"> ล้างค่า </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                เลือก
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-select
            :items="seller"
            v-model="sale_id"
            label="พนักงานขาย"
            item-text="firstname"
            item-value="id"
          ></v-select>
          <v-spacer></v-spacer>
          <v-select
            v-model="fillter_status"
            :items="status_sell"
            item-text="name"
            item-value="id"
            label="สถานะ"
          ></v-select>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div v-resize="onResize">
          <v-data-table
            :headers="headers"
            :items="orders"
            hide-default-footer
            :items-per-page="itemsPerPage"
            :class="{ mobile: isMobile }"
          >
            <template v-slot:item="{ item }">
              <tr v-if="!isMobile">
                <td>{{ dayjs(item.created).format("DD/MM/BBBB") }}</td>
                <td>{{ item.company_name }}</td>
                <td>
                  {{ item.customer_name }}
                </td>
                <td>
                  <v-chip class="ma-2" :color="item.color" text-color="white">
                    {{ item.CHANNEL }}
                  </v-chip>
                </td>
                <td>{{ item.product }}</td>
                <td>{{ item.detail }}</td>
                <td>{{ item.firstname }}</td>
                <td>
                  <v-chip
                    class="ma-2"
                    :color="item.status_color"
                    text-color="white"
                  >
                    {{ item.status }}
                  </v-chip>
                </td>
                <td>
                  {{
                    item.price
                      | currency("฿", 2, {
                        spaceBetweenAmountAndSymbol: true,
                      })
                  }}
                </td>
                <td>
                  <v-icon class="mr-2" @click="updateItem(item)">
                    add_comment
                  </v-icon>
                  <v-icon class="mr-2" @click="comment(item)"> comment </v-icon>
                  <v-icon @click="viewdoc(item)"> description </v-icon>
                  <span class="mr-2"></span>
                  <v-icon @click="deleteItem(item)"> delete </v-icon>
                </td>
              </tr>
              <tr v-else>
                <td>
                  <ul class="flex-content">
                    <li class="flex-item" data-label="วันที่">
                      {{ dayjs(item.created).format("DD/MM/BBBB") }}
                    </li>
                    <li class="flex-item" data-label="บริษัท">
                      {{ item.company_name }}
                    </li>
                    <li class="flex-item" data-label="ลูกค้า">
                      {{ item.customer_name }}
                    </li>
                    <li class="flex-item" data-label="ช่องทาง">
                      <v-chip
                        class="ma-2"
                        :color="item.color"
                        text-color="white"
                      >
                        {{ item.CHANNEL }}
                      </v-chip>
                    </li>
                    <li class="flex-item" data-label="สินค้า">
                      {{ item.product }}
                    </li>
                    <li class="flex-item" data-label="รายละเอียด">
                      {{ item.detail }}
                    </li>
                    <li class="flex-item" data-label="พนักงานขาย">
                      {{ item.firstname }}
                    </li>
                    <li class="flex-item" data-label="สถานะ">
                      <v-chip
                        class="ma-2"
                        :color="item.status_color"
                        text-color="white"
                      >
                        {{ item.status }}
                      </v-chip>
                    </li>
                    <li class="flex-item" data-label="ราคา">
                      {{
                        item.price
                          | currency("฿", 2, {
                            spaceBetweenAmountAndSymbol: true,
                          })
                      }}
                    </li>
                    <li class="flex-item" data-label="Action">
                      <v-icon class="mr-2" @click="updateItem(item)">
                        add_comment
                      </v-icon>
                      <v-icon class="mr-2" @click="comment(item)">
                        comment
                      </v-icon>
                      <v-icon @click="viewdoc(item)"> description </v-icon>
                      <span class="mr-2"></span>
                      <v-icon @click="deleteItem(item)"> delete </v-icon>
                    </li>
                  </ul>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>
        </div>

        <div class="text-center pt-3 mr-3 ml-3">
          <v-row>
            <v-col cols="12" sm="12" md="1">
              <p class="data_count">ข้อมูลทั้งหมด {{ totals }}</p>
            </v-col>
            <v-col cols="12" sm="12" md="10">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-col>
            <v-col cols="12" sm="12" md="1">
              <v-autocomplete
                dense
                v-model="itemsPerPage"
                :items="items"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- Delete  -->
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> แจ้งเตือน </v-card-title>

          <v-card-text> ต้องการลบข้อมูลหรือไม่ </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="dialog = false">
              ยกเลิก
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteconfirm()">
              ลบ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- UpdateStatus -->
      <v-dialog v-model="dialog_update" max-width="800">
        <v-card>
          <v-card-title class="text-h5">Status Update</v-card-title>
          <v-simple-table class="mr-4 ml-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">datetime</th>
                  <th class="text-left">status</th>
                  <th class="text-left">detail</th>
                  <th class="text-left">file</th>
                  <th class="text-left">user</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in status" :key="item.name">
                  <td>{{ dayjs(item.date).format("DD-MM-BBBB") }}</td>
                  <td>
                    <v-chip :color="item.color" text-color="white">
                      {{ item.status }}
                    </v-chip>
                  </td>
                  <td>{{ item.detail }}</td>
                  <td>
                    <div v-if="item.file != null">
                      <v-chip
                        class="mr-1 mb-1"
                        close
                        color="primary"
                        text-color="white"
                        small
                        v-for="data in item.file.split(',')"
                        :key="data.index"
                        close-icon="mdi-download-circle"
                        @click:close="download(data)"
                      >
                        {{ data | truncate(20) }}
                      </v-chip>
                    </div>
                  </td>
                  <td>{{ item.user }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <div class="mr-5 ml-5">
            <v-row class="formUpdate">
              <v-form ref="form">
                <v-col cols="12" md="12" sm="12">
                  <v-select
                    :rules="[rules.required]"
                    :items="all_status"
                    item-text="name"
                    item-value="id"
                    label="status"
                    v-model="form.status"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="form.status == 5">
                  <center>
                    <h2>
                      {{
                        form.price
                          | currency("฿", 2, {
                            spaceBetweenAmountAndSymbol: true,
                          })
                      }}
                    </h2>
                  </center>
                  <br />
                  <v-text-field
                    label="ยอดขาย"
                    hint="กรอกเฉพาะตัวเลยเท่านั้น"
                    persistent-hint
                    v-model="form.price"
                    outlined
                    prefix="฿"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-textarea
                    :rules="[rules.required, rules.counter]"
                    outlined
                    counter
                    v-model="form.content"
                    label="detail"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-file-input
                    show-size
                    chips
                    multiple
                    label="File input"
                    v-model="files"
                  ></v-file-input>
                </v-col>
              </v-form>
            </v-row>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="dialog_update = false">
              ยกเลิก
            </v-btn>

            <v-btn color="green darken-1" text @click="updateconfirm()">
              อัพเดต
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Comment -->
      <v-dialog v-model="dialog_comment" max-width="800">
        <v-card>
          <v-card-title class="text-h5"> Note </v-card-title>
          <div class="ma-5 myBox" v-if="comment_order.length != 0">
            <div
              class="comment pa-5 ma-3"
              v-for="item in comment_order"
              :key="item.index"
            >
              <v-row class="pa-4 comment-text">
                <p>{{ item.detail }}</p>
              </v-row>
              <v-row class="comment_detail">
                <v-col cols="12" md="8" sm="6">
                  <div v-if="item.file != null">
                    <v-chip
                      class="mr-1 mb-1"
                      close
                      color="primary"
                      text-color="white"
                      small
                      v-for="data in item.file.split(',')"
                      :key="data.index"
                      close-icon="mdi-download-circle"
                      @click:close="download_comment(data)"
                    >
                      {{ data | truncate(20) }}
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <h4>วันที่ :</h4>
                  <p>
                    {{ dayjs(item.created).format("DD-MM-BBBB") }} |
                    {{ dayjs(item.created).format("HH:mm:ss") }}
                  </p>
                  <br />
                  <h4>ชื่อ :</h4>
                  <p>{{ item.firstname }}</p>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="ma-5">
            <v-row class="formUpdate">
              <v-form ref="form">
                <v-col cols="12" md="12" sm="12">
                  <v-textarea
                    :rules="[rules.required, rules.counter]"
                    outlined
                    counter
                    v-model="comments.detail"
                    label="detail"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-file-input
                    show-size
                    chips
                    multiple
                    label="File input"
                    v-model="comments.files"
                  ></v-file-input>
                </v-col>
              </v-form>
            </v-row>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="green darken-1" text @click="dialog_comment = false">
              ยกเลิก
            </v-btn> -->
            <v-btn
              color="success"
              class="mb-2 white--text"
              @click="comment_add()"
            >
              บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import order from "../../services/orders";
import axios from "axios";
import setting from "@/services/setting";
import { orderUrl } from "@/services/constants";
import users from "@/services/users";

export default {
  data() {
    return {
      isMobile: false,
      date: "",
      pickerDate: null,
      arrayEvents: null,
      menu: false,
      items: [5, 10, 15, 20],
      page: 1,
      itemsPerPage: 10,
      search: "",
      pageCount: 0,
      totals: 0,
      headers: [
        {
          text: "date",
          align: "start",
          sortable: false,
          value: "created",
        },
        { text: "company", value: "company_name" },
        { text: "customer", value: "customer_name" },
        { text: "channel", value: "CHANNEL" },
        { text: "product", value: "product" },
        { text: "detail", value: "detail" },
        { text: "salesperson", value: "firstname" },
        { text: "status", value: "status" },
        { text: "price", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        size: (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
        counter: (value) => value.length <= 200 || "Minimum 10 characters",
      },
      orders: [],
      seller: [
        {
          firstname: "ทั้งหมด",
          id: "",
        },
      ],
      sale_id: "",
      status: [],
      all_status: [],
      status_sell: [
        {
          id: 0,
          name: "ทั้งหมด",
        },
      ],
      fillter_status: 0,
      search: "",
      dialog: false,
      dialog_update: false,
      dialog_comment: false,
      comment_id: "",
      delete_id: "",
      update_id: "",
      files: [],

      form: {
        status: "",
        content: "",
        price: 0,
      },
      comments: {
        detail: "",
        files: [],
      },
      comment_order: [],
    };
  },
  watch: {
    fillter_status() {
      this.get_order();
    },
    pickerDate(newval) {
      this.get_date(newval);
    },
    date() {
      this.get_order();
    },
    sale_id() {
      this.get_order();
    },
    search() {
      this.page = 1;
      this.get_order();
    },
    page() {
      this.get_order();
    },
    itemsPerPage() {
      this.get_order();
    },
  },

  mounted() {
    this.get_order();
    this.get_status();
    this.getsales();
  },
  methods: {
    async get_order() {
      // let users = await this.getsales();
      let formData = new FormData();
      formData.append("page", this.page);
      formData.append("perpage", this.itemsPerPage);
      formData.append("date", this.date);
      formData.append("status", this.fillter_status);
      formData.append("uid", localStorage.getItem("id"));
      formData.append("search", this.search);
      formData.append("sale", this.sale_id);
      let { data } = await order.get_order_manager(formData);
      this.orders = data.result;
      this.totals = data.total;
      this.orders = data.result;
      this.pageCount = data.length;
    },

    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },

    async get_date(date) {
      let formData = new FormData();
      formData.append("date", date);
      formData.append("uid", localStorage.getItem("id"));
      let { data } = await order.get_date_manager(formData);
      this.arrayEvents = data.result;
    },

    async get_status() {
      let { data } = await setting.get_status();
      this.all_status = data.status;
      this.status_sell = this.status_sell.concat(data.status);
    },
    initialize() {
      this.get_order();
    },
    viewdoc(item) {
      this.$router.push(`/view/${item.order_id}`);
    },
    deleteItem(item) {
      this.dialog = true;
      this.delete_id = item.order_id;
    },
    async deleteconfirm() {
      let result = await order.del_order(
        this.delete_id + "," + localStorage.getItem("id")
      );
      if (result) {
        this.dialog = false;
        this.get_order();
      }
    },
    async updateItem(item) {
      this.update_id = item.order_id;
      let result = await order.get_orderstatus(item.order_id);
      if (result.data.msg == "ok") {
        this.status = result.data.result;
      }
      this.dialog_update = true;
    },
    async updateconfirm() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("customer_status", this.form.status);
        formData.append("detail", this.form.content);
        formData.append("id", this.update_id);
        formData.append("price", this.form.price);
        formData.append("user", localStorage.getItem("id"));

        if (this.files.length !== 0) {
          for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            formData.append("files[" + i + "]", file);
          }
        }

        let result = await order.update_status(formData);
        if (result.data.msg == "ok") {
          this.clear();
        } else if (result.data.msg == "error") {
          alert(result.data.result);
        }
      }
    },

    download(file) {
      axios
        .get(`${orderUrl}/download/file/${file}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    download_comment(file) {
      axios
        .get(`${orderUrl}/download/file_cmoment/${file}`, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    async clear() {
      this.form = {
        status: "",
        price: 0,
        content: "",
      };

      await this.get_order();
      let result = await order.get_orderstatus(this.update_id);
      this.update_id = "";
      this.files = [];
      if (result.data.msg == "ok") {
        this.status = result.data.result;
      }
    },
    async getsales() {
      let { data } = await users.get_userOfmanager(localStorage.getItem("id"));
      this.seller = this.seller.concat(data.result);
    },
    // ***************************Comment Service *******************************

    async get_comment(id) {
      let result = await order.get_ordercomment(id);
      this.comment_order = await result.data.result;
      this.dialog_comment = true;
    },

    async comment(item) {
      this.comment_id = item.order_id;
      this.get_comment(item.order_id);
    },

    async comment_add() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("detail", this.comments.detail);
        formData.append("id", this.comment_id);
        formData.append("user", localStorage.getItem("id"));

        if (this.comments.files.length !== 0) {
          for (let i = 0; i < this.comments.files.length; i++) {
            let file = this.comments.files[i];
            formData.append("files[" + i + "]", file);
          }
        }
        let result = await order.update_comment(formData);
        if (result.data.msg == "ok") {
          // this.dialog_comment = false;
          this.get_comment(this.comment_id);
          this.comments = {
            detail: "",
            files: [],
          };
        } else if (result.data.msg == "error") {
          alert(result.data.result);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// desktop
.comment_detail h4 {
  font-size: 13px;
  display: inline;
}
.comment_detail p {
  font-size: 13px;
  display: inline;
}
.comment {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 20px;
}

.comment-text {
  background: #eceeef;
  border-radius: 10px;
}
.myBox {
  border: none;
  padding: 5px;
  width: 97%;
  height: 450px;
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #1793c5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4c5cd0;
}

.background-view {
  height: 100vh;
  background: linear-gradient(
    60deg,
    rgb(65, 120, 213) 0%,
    rgb(103, 211, 238) 100%
  );
}

.view-title {
  color: rgb(255, 255, 255);
}

.container {
  padding: 50px;
}
.formUpdate {
  display: grid;
}
.data_count {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

//mobile
.mobile {
  color: #333;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
    .view-title {
      color: rgb(255, 255, 255);
      padding-top: 10px;
    }
  }
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}
</style>
