<template>
  <div>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              label="ชื่อ"
              :rules="[rules.required]"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-file-input
              accept="image/*"
              label="Image"
              @change="onFileSelected"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              :rules="[rules.required]"
              :items="category"
              label="หมวดหมู่"
              item-text="name"
              item-value="id"
              v-model="form.category_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-textarea
              v-model="form.detail"
              label="รายละเอียด"
              :rules="[rules.required]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <center>
        <br />
        <div class="imgpreview">
          <v-img
            :src="getProductImage()"
            style="height: auto; max-width: 400px"
            class="mt-3"
          ></v-img>
        </div>
      </center>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="save()"> อัพเดต </v-btn>
    </div>
  </div>
</template>

<script>
import product from "@/services/product";
export default {
  props: ["items"],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 10 || "Minimum 10 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      category: [],
      form: {
        name: "",
        image: null,
        detail: "",
        category_id: "",
      },
    };
  },
  watch: {
    items(newVal) {
      this.form = {
        name: "",
        image: null,
        detail: "",
        category_id: "",
      };
      this.imageFile = null;
      this.form = newVal;
    },
  },
  mounted() {
    this.getCategory();
    this.form = this.items;
  },
  methods: {
    getProductImage() {
      // if (this.form.image.length > 100) {
      //   return this.form.image;
      // } else {
      return this.$options.filters.imageUrl(this.form.image);
      // }
    },
    onFileSelected(event) {
      // For loading preview image
      const reader = new FileReader();
      reader.onload = (event) => {
        this.form.image = event;
      };
      reader.readAsDataURL(event);
      // For uploading image
      this.imageFile = event;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        const { id, name, detail, category_id } = this.form;

        formData.append("id", id);
        formData.append("name", name);
        formData.append("detail", detail);
        formData.append("category", category_id);

        if (this.imageFile != null) {
          formData.append("image", this.imageFile);
        }
        let result = await product.update_product(formData);
        if (result.data.msg == "ok") {
          this.clear();
        } else {
        }
      }
    },
    async getCategory() {
      let result = await product.get_category();
      this.category = result.data.result;
    },

    clear() {
      this.form = {
        name: "",
        image: null,
        detail: "",
        category_id: "",
      };
      this.$emit("dialog", false);
    },
  },
};
</script>

<style scoped>
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
