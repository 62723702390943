<template>
  <div class="container">
    <h1>ยอดขายรวม</h1>
    <div class="main_target">
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <center>
            <div class="show_target">
              <h1>
                {{
                  targets.month
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </h1>
              <p>เป้าการขายเดือนนี้</p>
            </div>
          </center>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <center>
            <div class="show_target">
              <h1>
                {{
                  actual
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </h1>
              <p>ยอดที่ทำได้</p>
            </div>
          </center>
        </v-col>
      </v-row>
    </div>
    <div class="select_month">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="month"
            max-width="290px"
            label="เดือนที่ต้องการดู"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
          locale="th"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">ยกเลิก </v-btn>
          <v-btn text color="primary" @click="get_sum_targets()"> เลือก </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ลำดับ</th>
            <th class="text-left">ชื่อ</th>
            <th class="text-left">ยอดขายจริง</th>
            <th class="text-left">เปอร์เซนต์</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in reports" :key="item.index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>
              <v-chip class="ma-2" color="primary">
                {{
                  item.total
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </v-chip>
            </td>
            <td>
              <v-chip dark class="ma-2" color="orange"
                >{{ ((item.total / targets.month) * 100).toFixed(2) }} %</v-chip
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import report from "@/services/reports";
export default {
  data() {
    return {
      reports: [],
      date: "",
      menu: false,
      month: "",
      targets: [],
      actual: 0,
    };
  },
  mounted() {
    this.date = new Date().toISOString().substr(0, 7); 
    this.get_sum_targets();
  },
  methods: {
    async get_sum_targets() {
      let formData = new FormData();

      formData.append("uid", localStorage.getItem("id"));
      formData.append("date", this.date);

      let { data } = await report.user_all_report(formData);
      if (data.msg == "ok") {
        this.reports = data.result;
        this.actual = data.actual;
        this.targets = data.targets[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 30px;
}
.v-text-field {
  width: 400px;
}
.select_month {
  margin-top: 10px;
}
.main_target {
  margin: 30px;
}
.show_target {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 20px;
  width: 80%;
  height: 20vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 45px;
    color: rgb(81, 46, 217);
  }
}
</style>
