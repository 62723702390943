<template>
  <div>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              label="ชื่อ"
              :rules="[rules.required]"
              v-model="form.firstname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              v-model="form.lastname"
              label="นามสกุล"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              v-model="form.username"
              label="Username"
              :rules="[rules.required, rules.counter_username]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              :rules="[rules.required]"
              :items="rule_data"
              label="ระดับ"
              item-text="name"
              item-value="id"
              v-model="form.rule"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="save()"> อัพเดต </v-btn>
    </div>
  </div>
</template>

<script>
import setting from "@/services/setting";
import user from "@/services/users";
export default {
  props: ["items"],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 6 || "Minimum 6 characters",
        counter_username: (value) =>
          value.length >= 3 || "Minimum 3 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      rule_data: [],
      form: {
        firstname: "",
        lastname: "",
        username: "",
        rule: "",
      },
    };
  },
  watch: {
    items(newVal) {
      this.form = newVal;
    },
  },
  mounted() {
    this.getRules();
    this.form = this.items;
  },
  methods: {
    async getRules() {
      let result = await setting.get_rules();
      this.rule_data = result.data.result;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          formData.append(`${key}`, value);
        }
        let result = await user.put_user(formData);
        if (result.data.msg == "ok") {
          this.$emit("snackbar", "อัพเดตข้อมูลสำเร็จ", "green");
          this.clear();
        } else {
        }
      }
    },
    changeStatus() {
      if (this.checkbox == true) {
        this.checkbox = false;
      } else {
        this.checkbox = true;
        this.form.company_id = null;
      }
    },
    clear() {
      this.form = {
        firstname: "",
        lastname: "",
        username: "",
        rule: "",
      };
      this.$emit("dialog", false);
    },
  },
};
</script>

<style scoped>
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
