var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("สินค้า")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" เพิ่มสินค้า ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("สินค้าใหม่")])]),_c('v-card-text',[_c('AddProduct',{on:{"dialog":_vm.set_dailog}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.Editdialog),callback:function ($$v) {_vm.Editdialog=$$v},expression:"Editdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("แก้ไขลูกค้า")])]),_c('v-card-text',[_c('EditProduct',{attrs:{"items":_vm.editedItem},on:{"dialog":_vm.set_editdailog}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("คุณต้องการลบหรือไม่")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("ยกเลิก")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("ลบ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":_vm._f("imageUrl")(item.image),"lazy-src":"https://picsum.photos/id/11/10/6","aspect-ratio":"1","max-width":"100","max-height":"100"}})],1),_c('td',[_vm._v(" "+_vm._s(item.category)+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("truncate")(item.detail,120)))]),_c('td',[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('span',{staticClass:"ma-1"}),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }