<template>
  <div>
    <v-btn href="https://www.dataforthai.com/" target="_blank">ref</v-btn>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.name"
            label="ชื่อบริษัท"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.address"
            label="ที่อยู่"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
            v-model="form.provinces"
            :rules="Rules"
            required
            :items="provinces"
            item-text="name_th"
            item-value="id"
            label="จังหวัด"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
            v-model="form.amphures"
            :rules="Rules"
            required
            :items="amphures"
            item-text="name_th"
            item-value="id"
            label="อำเภอ"
            :disabled="form.provinces == ''"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
            v-model="form.districts"
            :rules="Rules"
            required
            :items="districts"
            item-text="name_th"
            item-value="id"
            label="ตำบล"
            :disabled="form.amphures == ''"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4" sm="4">
          <v-text-field
            :rules="Rules"
            required
            v-model="form.zip_code"
            label="รหัสไปรษณีย์"
            :disabled="form.districts == ''"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.email"
            type="email"
            label="อีเมล"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.tel"
            type="number"
            label="เบอร์ติดต่อ"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-textarea
            outlined
            v-model="form.detail"
            type="email"
            label="ประกอบธุรกิจ"
            :rules="Rules"
            required
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.registration_number"
            type="number"
            label="เลขทะเบียน"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="form.registration_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.registration_date"
                label="วันที่จดทะเบียน"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="Rules"
                required
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.registration_date"
              no-title
              scrollable
              locale="th-th"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(form.registration_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.registed_capital"
            type="number"
            label="ทุนจดทะเบียน"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="update()"> อัพเดต </v-btn>
    </div>
  </div>
</template>

<script>
import setting from "@/services/setting";
import company from "@/services/company";
export default {
  props: ["editItem"],
  data() {
    return {
      Rules: [(v) => !!v || "Required"],
      valid: false,
      menu: false,
      provinces: [],
      amphures: [],
      districts: [],
      form: {
        name: "",
        address: "",
        provinces: "",
        amphures: "",
        districts: "",
        zip_code: "",
        email: "",
        tel: "",
        detail: "",
        registration_number: "",
        registration_date: "",
        registed_capital: "",
      },
    };
  },
  computed: {},
  watch: {
    "form.provinces"(newVal) {
      if (newVal != "") {
        this.getAmphures(newVal);
      }
    },
    "form.amphures"(newVal) {
      if (newVal != "") {
        this.getDistricts(newVal);
      }
    },
    districts_data(newVal) {
      this.form.districts = newVal.id;
      this.form.zip_code = newVal.zip_code;
    },
    editItem(newVal) {
      this.form = {
        name: newVal.name,
        address: newVal.address,
        provinces: newVal.provinces_id,
        amphures: newVal.amphures_id,
        districts: newVal.districts_id,
        zip_code: newVal.zip_code,
        email: newVal.email,
        tel: newVal.tel,
        detail: newVal.detail,
        registration_number: newVal.regis_number,
        registration_date: newVal.regis_date.split("T")[0],
        registed_capital: newVal.regis_capital,
        company_id: newVal.id,
        address_id: newVal.address_id,
      };
    },
  },
  mounted() {
    this.getProvinces();
    this.form = {
      name: this.editItem.name,
      address: this.editItem.address,
      provinces: this.editItem.provinces_id,
      amphures: this.editItem.amphures_id,
      districts: this.editItem.districts_id,
      zip_code: this.editItem.zip_code,
      email: this.editItem.email,
      tel: this.editItem.tel,
      detail: this.editItem.detail,
      registration_number: this.editItem.regis_number,
      registration_date: this.editItem.regis_date.split("T")[0],
      registed_capital: this.editItem.regis_capital,
      company_id: this.editItem.id,
      address_id: this.editItem.address_id,
    };
  },
  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          formData.append(`${key}`, value);
        }

        let result = await company.put_company(formData);
        if (result) {
          this.reset();
        }
      }
    },
    async getProvinces() {
      let result = await setting.get_provinces();
      this.provinces = result.data.result;
    },
    async getAmphures(id) {
      let result = await setting.get_amphures(id);
      this.amphures = result.data.result;
    },
    async getDistricts(id) {
      let result = await setting.get_districts(id);
      this.districts = result.data.result;
    },
    async reset() {
      this.form = {
        name: "",
        address: "",
        provinces: "",
        amphures: "",
        districts: "",
        zip_code: "",
        email: "",
        tel: "",
        detail: "",
        registration_number: "",
        registration_date: "",
        registed_capital: "",
        company_id: "",
        address_id: "",
      };
      this.$emit("dialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
