import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

const getchannel = async () => {
  return httpClient.get(server.CHANNEL_URL);
};

const get_status = async () => {
  return httpClient.get("status");
};

const get_demand = async () => {
  return httpClient.get("demand");
};

const get_provinces = async () => {
  return httpClient.get("provinces");
};

const get_amphures = async (id) => {
  return httpClient.get("amphures" + `/id/${id}`);
};

const get_districts = async (id) => {
  return httpClient.get("districts" + `/id/${id}`);
};

const get_rules = async () => {
  return httpClient.get("rules");
};

const get_sales = async () => {
  return httpClient.get("sales");
};

const get_type = async () => {
  return httpClient.get("type");
};

const get_pipe = async () => {
  return httpClient.get("pipe");
};

const get_usernull = async () => {
  return httpClient.get("usernull");
};

// Update

const put_channel = async (data) => {
  return httpClient.put("channel", data);
};

const put_status = async (data) => {
  return httpClient.put("status", data);
};

// insert

const add_channel = async (data) => {
  return httpClient.post("channel", data);
};

const add_status = async (data) => {
  return httpClient.post("status", data);
};

// Delete

const del_channel = async (id) => {
  return httpClient.delete("channel" + `/id/${id}`);
};

const del_status = async (id) => {
  return httpClient.delete("status" + `/id/${id}`);
};

export default {
  // Get
  getchannel,
  get_status,
  get_demand,
  get_provinces,
  get_amphures,
  get_districts,

  get_rules,
  get_sales,
  get_type,
  get_pipe,
  get_usernull,

  // Update
  put_channel,
  put_status,

  // Insert
  add_channel,
  add_status,

  // Delete
  del_channel,
  del_status,
};
