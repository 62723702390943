import httpClient from "@/services/httpClient";

const add_user = async (values) => {
  return httpClient.post("signup", values);
};

const get_user = async (values) => {
  return httpClient.get("users", values);
};

const get_userByid = (id) => {
  return httpClient.get("users" + `/id/${id}`);
};

const del_user = (id) => {
  return httpClient.delete("users" + `/id/${id}`);
};

const put_user = async (data) => {
  return httpClient.put("users", data);
};

const get_manager = async () => {
  return httpClient.get("manager");
};

const get_userOfmanager = async (id) => {
  return httpClient.get("manager" + `/user/${id}`);
};

const get_userSale = async (id) => {
  return httpClient.get("manager" + `/user/`);
};

const add_userManager = async (data) => {
  return httpClient.post("manager", data);
};

const del_userOfmanager = (id) => {
  return httpClient.delete("manager" + `/user/${id}`);
};

const put_password = async (data) => {
  return httpClient.put("password", data);
};

export default {
  add_user,
  get_user,
  del_user,
  put_user,
  get_manager,
  get_userOfmanager,
  get_userSale,
  add_userManager,
  del_userOfmanager,
  put_password,
  get_userByid,
};
