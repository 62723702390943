<template>
  <div>
    <!-- <v-btn href="https://www.dataforthai.com/" target="_blank">ref</v-btn> -->
    <v-btn color="red lighten-2" dark @click="dialog = true"> Search </v-btn>

    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.name"
            label="ชื่อบริษัท"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.address"
            label="ที่อยู่"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
            v-model="form.provinces"
            :rules="Rules"
            required
            :items="provinces"
            item-text="name_th"
            item-value="id"
            label="จังหวัด"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
            v-model="form.amphures"
            :rules="Rules"
            required
            :items="amphures"
            item-text="name_th"
            item-value="id"
            label="อำเภอ"
            :disabled="form.provinces == ''"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
            v-model="form.districts"
            :rules="Rules"
            required
            :items="districts"
            item-text="name_th"
            item-value="id"
            label="ตำบล"
            :disabled="form.amphures == ''"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4" sm="4">
          <v-text-field
            :rules="Rules"
            required
            v-model="form.zip_code"
            label="รหัสไปรษณีย์"
            :disabled="form.districts == ''"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.email"
            type="email"
            label="อีเมล"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.tel"
            type="number"
            label="เบอร์ติดต่อ"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.detail"
            type="email"
            label="ประกอบธุรกิจ"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.registration_number"
            type="number"
            label="เลขทะเบียน"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="form.registration_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.registration_date"
                label="วันที่จดทะเบียน"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="Rules"
                required
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.registration_date"
              no-title
              scrollable
              locale="th-th"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(form.registration_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-text-field
            v-model="form.registed_capital"
            type="number"
            label="ทุนจดทะเบียน"
            :rules="Rules"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="save()"> บันทึก </v-btn>
    </div>
    <div>
      <v-dialog v-model="dialog" width="750">
        <v-card>
          <v-card-title class="text-h5 blue lighten-2">
            ค้นหาข้อมูลบริษัท
          </v-card-title>
          <br />
          <v-card-text>
            <v-form
              ref="forms"
              v-model="valids"
              @submit.prevent="search_company()"
              ><v-text-field
                v-model="search"
                :rules="Rules"
                required
                solo
                rounded
                label="ชื่อบริษัท"
                clearable
                append-outer-icon="mdi-magnify"
                @click:append-outer="search_company()"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <div class="text-center" v-if="progress">
            <v-progress-circular
              :size="70"
              :width="7"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>

          <div class="company_count">
            <h4 v-if="count != 0">ผลลัพธ์การค้นหา {{ count }} รายการ</h4>
          </div>

          <v-divider v-if="count != 0"></v-divider>

          <div class="company_show">
            <div
              v-for="item in company"
              :key="item.index"
              class="company_item"
              @click.prevent="company_detail(item.JP_NO)"
            >
              <h3 style="color: #2167ba">{{ item.JP_TNAME }}</h3>
              <br />
              <p>ชื่อภาษาอังกฤษ :</p>
              <p>{{ item.JP_ENAME }}</p>
              <br />
              <p>ประเภทนิติบุคคล :</p>
              <p>{{ item.JP_TYPE_CODE }}</p>
              <br />
              <p
                v-bind:style="[
                  item.STATUS == 'ยังดำเนินกิจการอยู่'
                    ? { color: 'green' }
                    : { color: 'red' },
                ]"
              >
                สถานะ :
              </p>
              <p
                v-bind:style="[
                  item.STATUS == 'ยังดำเนินกิจการอยู่'
                    ? { color: 'green' }
                    : { color: 'red' },
                ]"
              >
                {{ item.STATUS }}
              </p>
              <br />
              <p>วัตถุประสงค์ :</p>
              <p>{{ item.OBJ_TNAME }}</p>
              <br />
            </div>
          </div>

          <div class="company_pagination" v-if="count > 10">
            <v-pagination
              v-model="page"
              :length="Math.ceil(count / 10)"
              :total-visible="7"
              circle
            ></v-pagination>
          </div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> ปิด </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import setting from "@/services/setting";
import company from "@/services/company";
import search_company from "@/services/search_company";
export default {
  data() {
    return {
      progress: false,
      search: "",
      dialog: false,
      Rules: [(v) => !!v || "Required"],
      valid: false,
      valids: false,
      menu: false,
      provinces: [],
      amphures: [],
      districts: [],
      districts_data: {},
      form: {
        name: "",
        address: "",
        provinces: "",
        amphures: "",
        districts: "",
        zip_code: "",
        email: "",
        tel: "",
        detail: "",
        registration_number: "",
        registration_date: "",
        registed_capital: "",
      },
      company: [],
      count: 0,
      page: 1,
    };
  },

  watch: {
    "form.provinces"(newVal) {
      if (newVal != "") {
        this.getAmphures(newVal);
      }
    },
    "form.amphures"(newVal) {
      if (newVal != "") {
        this.getDistricts(newVal);
      }
    },
    "form.districts"(newVal) {
      if (newVal != "") {
        const index = this.districts.findIndex(
          (districts) => districts.id == newVal
        );
        this.form.zip_code = this.districts[index].zip_code;
      }
    },
    async page(newVal) {
      let formData = new FormData();
      formData.append(`keyword`, this.search);
      formData.append(`page`, newVal);

      let result = await search_company.search(formData);
      if (result) {
        this.count = parseInt(result.data.count);
        this.company = result.data.result;
      }
    },
  },
  mounted() {
    this.getProvinces();
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          formData.append(`${key}`, value);
        }
        let result = await company.add_company(formData);
        if (result) {
          this.reset();
        }
      }
    },
    async getProvinces() {
      let result = await setting.get_provinces();
      this.provinces = result.data.result;
    },
    async getAmphures(id) {
      let result = await setting.get_amphures(id);
      this.amphures = result.data.result;
    },
    async getDistricts(id) {
      // Amphures id
      let result = await setting.get_districts(id);
      this.districts = result.data.result;
    },
    async reset() {
      this.form = {
        name: "",
        address: "",
        provinces: "",
        amphures: "",
        districts: "",
        zip_code: "",
        email: "",
        tel: "",
        detail: "",
        registration_number: "",
        registration_date: "",
        registed_capital: "",
      };
      this.$emit("dialog", false);
    },
    async search_company() {
      if (this.$refs.forms.validate()) {
        this.progress = true;
        let formData = new FormData();

        formData.append(`keyword`, this.search);
        formData.append(`page`, 1);

        let result = await search_company.search(formData);
        if (result) {
          this.count = parseInt(result.data.count);
          this.company = result.data.result;
          this.progress = false;
          // console.log(this.company);
        }
      }
    },
    async company_detail(id) {
      let result = await search_company.get_company(id);
      if (result.status == 200) {
        let company = result.data;
        await this.getDistricts(company.districts);
        // console.log(result.data);

        this.form = {
          name: company.name,
          address: company.location,
          provinces: company.province,
          // amphures: "",
          // districts: "",
          // zip_code: "",
          detail: company.detail,
          registration_number: company.regis_number,
          registration_date: company.regis_date,
          registed_capital: company.registered_capital,
        };
        // อำเภอ
        this.form.amphures = company.districts;

        // ตำบล
        this.form.districts = `${company.subdistricts}`;

        // รหัสไปรษณีย์
        this.form.zip_code = company.zipcode;

        this.dialog = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company_pagination {
  text-align: center;
  margin: 15px;
}
.company_count {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 5px;
}
.company_item {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

h3 {
  display: inline;
}
p {
  display: inline;
}
.company_show {
  margin: 15px;
}
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
