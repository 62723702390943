import Vue from "vue";
import Vuex from "vuex";
import auth from "@/services/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    username: "",
    rule: "",
    uid: "",
    alert: {
      snackbar: false,
      text: "",
      color: "",
    },
  },
  getters: {
    isLogin(state) {
      return state.isLogged;
    },
    username(state) {
      return state.username;
    },
    token(state) {
      return state.token;
    },
    alerts(state) {
      return state.alert;
    },
    rule(state) {
      return state.rule;
    },
    uid(state) {
      return state.uid;
    },
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.isLogged = true;
    },
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
      state.rule = "";
      state.uid = "";
      state.username = "";
    },
    SET_RULE(state, rule) {
      state.rule = rule;
    },
    SET_UID(state, uid) {
      state.uid = uid;
    },
    SET_ALERT(state, alert) {
      state.alert = alert;
    },
  },
  actions: {
    restoreLogin({ commit }) {
      if (auth.isLoggedIn() == true) {
        let username = localStorage.getItem("firstname");
        let rule = localStorage.getItem("rule");
        let uid = localStorage.getItem("id");
        commit("SET_LOGGED_IN");
        commit("SET_USERNAME", username);
        commit("SET_RULE", rule);
        commit("SET_UID", uid);
      }
    },
    async doLogin({ commit, dispatch }, { username, password }) {
      let result = await auth.login({ username, password });
      if (result !== false) {
        commit("SET_LOGGED_IN");
        commit("SET_USERNAME", result.user.firstname);
        commit("SET_RULE", result.user.rule);
      } else {
        dispatch("doLogout", {});
      }
    },
    doLogout({ commit }) {
      auth.logoff();
      commit("SET_LOGGED_OUT");
      commit("SET_USERNAME", "");
    },
    openAlert({ commit }, { text, color }) {
      // console.log(text, color);
      commit("SET_ALERT", { snackbar: true, text: text, color: color });
    },
  },
  modules: {},
});
