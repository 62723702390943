import httpClient from "@/services/httpClient";

const search = async (data) => {
  return httpClient.post("/company/search/", data);
};

const get_company = async (id) => {
  return httpClient.get("company" + `/id/${id}`);
};

export default { search, get_company };
