<template>
  <div>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              label="ชื่อ"
              :rules="[rules.required]"
              v-model="form.firstname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              v-model="form.lastname"
              label="นามสกุล"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              v-model="form.username"
              label="Username"
              :rules="[rules.required, rules.counter_username]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              type="password"
              v-model="form.password"
              label="Password"
              :rules="[rules.required, rules.counter]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              :rules="[rules.required]"
              :items="rule_data"
              label="ระดับ"
              item-text="name"
              item-value="id"
              v-model="form.rule"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="save()"> บันทึก </v-btn>
    </div>
  </div>
</template>

<script>
import setting from "@/services/setting";
import users from "@/services/users";

export default {
  components: {},
  data() {
    return {
      snackbar: {},
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 6 || "Minimum 6 characters",
        counter_username: (value) =>
          value.length >= 3 || "Minimum 3 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      rule_data: [],
      form: {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        rule: "",
      },
      checkbox: false,
    };
  },
  mounted() {
    this.getRules();
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("firstname", this.form.firstname);
        formData.append("lastname", this.form.lastname);
        formData.append("username", this.form.username);
        formData.append("password", this.form.password);
        formData.append("rule", this.form.rule);

        let result = await users.add_user(formData);
        let data = result.data;

        if (data.status == 409) {
          this.$emit("snackbar", result.data.msg, "red");
        }
        if (data.status == 201) {
          this.$emit("snackbar", result.data.msg, "green");
          this.clear();
        }
      }
    },
    async getRules() {
      let result = await setting.get_rules();
      this.rule_data = result.data.result;
    },
    clear() {
      this.form = {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        rule: "",
      };
      this.$emit("dialog", false);
    },
  },
};
</script>

<style scoped>
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
