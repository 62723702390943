<template>
  <div>
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
      max-width="400"
    ></apexchart>
  </div>
</template>

<script>
import dashboards from "@/services/dashboards";
export default {
  props: ["date"],
  watch: {
    date(newVal) {
      this.reset();
      this.generate(newVal);
    },
  },
  data() {
    return {
      chartOptions: {
        labels: [],
      },
      series: [],
    };
  },
  created() {
    this.generate(this.date);
  },
  methods: {
    async generate(date) {
      let formData = new FormData();
      formData.append("date", date);
      let { data } = await dashboards.get_counter_user(formData);
      let product = data.result;
      this.chartOptions = {
          labels: product.map((prod) => prod.firstname),
          theme: {
            palette: "palette1",
          },
        };
      this.series = product.map((prod) => prod.c)
        
    },
    reset() {
      this.chartOptions = {
        labels: [],
        colors: [],
      };
      this.series = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 200px;
    border-radius: 10px;
    height: 130px;
    padding: 30px;
    margin: 20px;
    line-height: 40px;
    color: white;
  }
}
</style>
