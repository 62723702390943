import httpClient from "@/services/httpClient";

const add_order = async (data) => {
  return httpClient.post("order", data);
};

const get_order = async (data) => {
  return httpClient.post("orders", data);
};

const get_date = async (data) => {
  return httpClient.post("order_date", data);
};

// **************** Delete ************************
const del_order = (id) => {
  return httpClient.delete("order" + `/id/${id}`);
};

const update_status = (data) => {
  return httpClient.post("order_status", data);
};

const get_orderstatus = async (id) => {
  return httpClient.get("order" + `/status/${id}`);
};

const get_orderByid = async (id) => {
  return httpClient.get("order" + `/id/${id}`);
};

const get_orderByuser = async (data) => {
  return httpClient.post("order/uid", data);
};

const get_date_manager = async (data) => {
  return httpClient.post("order_date_manager", data);
};

const get_order_manager = async (data) => {
  return httpClient.post("order_manager", data);
};

// Comment Service

const update_comment = (data) => {
  return httpClient.post("order_comment", data);
};

const get_ordercomment = async (id) => {
  return httpClient.get("order" + `/comment/${id}`);
};

// Update 
const order_price = async (data) => {
  return httpClient.post("order_price", data);
};

export default {
  add_order,
  get_order,
  del_order,
  update_status,
  get_orderstatus,
  get_orderByid,
  get_orderByuser,
  update_comment,
  get_ordercomment,
  get_date,
  get_order_manager,
  get_date_manager,
  order_price
};
