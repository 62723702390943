<template>
  <div>
    <v-row>
      <v-spacer> </v-spacer>
      <v-btn
        color="primary"
        dark
        class="mr-4 mb-5"
        @click="dialog_insert = true"
      >
        เพิ่มหมวดหมู่
      </v-btn>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="category"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>
            {{ item.prod }}
          </td>
          <td>
            <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
            <span class="ma-1"></span>
            <v-icon @click="delItem(item)"> delete </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- dailog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    label="id"
                    readonly
                    v-model="form.id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    label="name"
                    hint="facebook,line"
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ปิด
          </v-btn>
          <v-btn color="blue darken-1" text @click="update()"> อัพเดต </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dailog Insert-->
    <v-dialog v-model="dialog_insert" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Insert</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    :rules="[rules.required]"
                    label="name"
                    hint="facebook,line"
                    v-model="form_insert.name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_insert = false">
            ปิด
          </v-btn>
          <v-btn color="blue darken-1" text @click="insert()">เพิ่ม</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dailog Delete-->
    <v-dialog v-model="dialog_del" persistent max-width="200">
      <v-card>
        <v-card-title class="text-h5"> Alret </v-card-title>
        <v-card-text>คุณต้องการลบหรือไม่</v-card-text>
        <v-card-actions class="ml-3">
          <v-btn color="green darken-1" text @click="dialog_del = false">
            ปิด
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteitem()"> ลบ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import setting from "@/services/setting";
import product from "@/services/product";
export default {
  data: () => ({
    dialog: false,
    dialog_insert: false,
    dialog_del: false,
    category: [],
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length >= 10 || "Minimum 10 characters",
    },
    headers: [
      { text: "name", value: "name" },
      { text: "product", value: "prod" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    form: {
      id: "",
      name: "",
    },
    form_insert: {
      name: "",
    },
    del_id: "",
  }),
  mounted() {
    this.get_category();
  },
  methods: {
    async get_category() {
      let result = await product.get_category();
      this.category = result.data.result;
    },
    editItem(item) {
      this.form = item;
      this.dialog = true;
    },
    delItem(item) {
      this.del_id = item.id;
      this.dialog_del = true;
    },
    async update() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        const { name, id } = this.form;
        formData.append("id", id);
        formData.append("name", name);

        let result = await product.update_category(formData);
        if (result.data.msg == "ok") {
          this.get_category();
          this.clear();
          this.dialog = false;
        }
      }
    },
    async insert() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        const { name } = this.form_insert;
        formData.append("name", name);
        let result = await product.add_category(formData);
        if (result.data.msg == "ok") {
          this.get_category();
          this.clear();
          this.dialog_insert = false;
        } else {
          alert(result.data.msg);
        }
      }
    },
    async deleteitem() {
      let result = await product.delete_category(this.del_id);
      if (result.data.msg == "ok") {
        this.get_category();
        this.dialog_del = false;
      } else if (result.data.msg == "error") {
        alert(result.data.msg);
      }
    },
    clear() {
      this.form = {
        id: "",
        name: "",
      };
      this.form_insert = {
        name: "",
      };
    },
  },
};
</script>

<style></style>
