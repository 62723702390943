import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vue2Filters from "vue2-filters";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { imageUrl } from "@/services/constants";
import { profileUrl } from "@/services/constants";
import io from "socket.io-client";
// app.config.globalProperties.$soketio = io('YOUR-SOCKET-IO-SERVER-HOSTNAME-WITH-PORT');
Vue.prototype.$soketio = io(process.env.VUE_APP_SOCKET_ENDPOINT);

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueNativeNotification from "vue-native-notification";
Vue.use(VueNativeNotification, {
  requestOnNotify: true,
});

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

const buddhistEra = require("dayjs/plugin/buddhistEra");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advancedFormat = require("dayjs/plugin/advancedFormat");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);
dayjs.locale("th");

dayjs.tz.guess();
// dayjs.tz.setDefault("Asia/Bangkok")

Vue.prototype.dayjs = dayjs;

Vue.use(Vue2Filters);
Vue.config.productionTip = false;

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./registerServiceWorker";
import "./registerServiceWorker";
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(Vue2Filters);

Vue.filter("imageUrl", (image) => {
  return `${imageUrl}/${image}?timestamp=${Date.now()}`;
});

Vue.filter("profileUrl", (image) => {
  return `${profileUrl}/${image}?timestamp=${Date.now()}`;
});
