<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>SALES TARGET</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template> -->
      <!--***************************************** Table ************************************************* -->

      <template v-slot:item="{ item, index }">
        <tr>
          <td>{{ index + 1 }}</td>
          <td>{{ item.firstname }} {{ item.lastname }}</td>
          <td>
            <v-chip color="green" dark v-if="item.target != null">
              {{
                item.target
                  | currency("฿", 0, {
                    spaceBetweenAmountAndSymbol: true,
                  })
              }}
            </v-chip>
            <v-chip color="green" dark v-else>
              {{
                0
                  | currency("฿", 0, {
                    spaceBetweenAmountAndSymbol: true,
                  })
              }}
            </v-chip>
          </td>
          <td>
            <v-icon class="mr-2" @click="open_target(item)">
              add_circle
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Dialog -->
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-toolbar color="primary" dark><h2>ตั้งค่าเป้าการขาย</h2></v-toolbar>

        <v-card-text>
          <br /><br />
          <v-row>
            <v-col cols="12" md="7" sm="12"
              ><date-picker
                v-model="date"
                type="year"
                format="YYYY"
                placeholder="ปี"
              ></date-picker>
              <date-picker
                v-model="endTime"
                type="date"
                placeholder="วันที่สิ้นสุด"
              ></date-picker>
            </v-col>
            <v-col cols="12" md="5" sm="12"
              ><h1>{{ target | currency("฿", 0) }}</h1></v-col
            >
          </v-row>

          <br /><br />
          <v-expand-transition>
            <v-alert :type="expand.type" v-show="expand.status" dense>
              {{ expand.text }}
            </v-alert>
          </v-expand-transition>

          <div v-if="show_user != null">
            <h3>พนักงาน :</h3>
            <p style="font-size: 16px">
              {{ show_user.firstname }} {{ show_user.lastname }}
            </p>
          </div>
          <br />
          <v-text-field
            :readonly="target_money"
            label="เป้าการขาย"
            hint="กรอกเฉพาะตัวเลยเท่านั้น"
            persistent-hint
            v-model="target"
            outlined
            :prefix="currency"
            type="number"
          >
          </v-text-field>
        </v-card-text>

        <v-divider></v-divider>
        <!-- Data Table -->
        <div class="target_table">
          <v-simple-table height="300px" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">เป้าการขาย</th>
                  <th class="text-left">ปี</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in targets" :key="item.name">
                  <td>
                    {{
                      item.target
                        | currency("฿", 0, {
                          spaceBetweenAmountAndSymbol: true,
                        })
                    }}
                  </td>
                  <td>{{ item.year }}</td>
                  <td>
                    <v-btn
                      elevation="2"
                      color="primary"
                      x-small
                      :disabled="item.id == target_id"
                      @click.prevent="update_Usertarget(item.id)"
                      >ตั้งค่า</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="btn_check"
            text
            @click="add_target()"
          >
            เพิ่มการตั้งค่า
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sales from "@/services/sales";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";

export default {
  components: { DatePicker },
  data() {
    return {
      currency: "฿",
      expand: {
        status: false,
        text: "",
        type: "success",
      },
      btn_check: false,
      dialog: false,
      target_money: true,
      date: "",
      endTime: "",
      users: [],
      headers: [
        { text: "NO.", align: "start", sortable: false },
        {
          text: "ชื่อ",
          value: "firstname",
          sortable: false,
        },
        { text: "เป้าการขาย", value: "rule_name", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      show_user: null,
      id: "",
      target: 0,
      targets: [],
      target_id: "",
    };
  },
  watch: {
    date(newVal) {
      if (newVal != "" && newVal != null) {
        this.check_target(newVal);
      }
    },
  },
  mounted() {
    this.get_user();
  },
  methods: {
    async get_user() {
      let { data } = await sales.get_targets();
      if (data.msg == "ok") {
        this.users = data.result;
        // console.log(data);
      } else {
        alert(data.msg);
      }
    },
    async check_target(year) {
      let { data } = await sales.check_targets(
        this.dayjs(year).format("YYYY") + "," + this.id
      );
      if (data.length == 0) {
        this.set_expand(true, "ยังไม่มีการตั้งค่าในปีนี้", "success");
        this.target_money = false;
        this.target = 0;
        this.endTime = "";
        this.btn_check = false;
        setTimeout(() => {
          this.set_expand(false, "", "success");
        }, 3000);
      } else {
        this.target = data.result[0].target;
        this.target_money = true;
        this.btn_check = true;
        this.set_expand(true, "มีการตั้งค่าในปีนี้แล้ว", "error");
        setTimeout(() => {
          this.set_expand(false, "", "success");
        }, 3000);
      }
    },
    open_target(item) {
      this.target_id = item.target_id;
      this.id = item.id;
      this.get_user_target(item.id);
      this.show_user = item;
      this.target_money = true;
      this.target = 0;
      this.date = "";
      this.endTime = "";
      this.btn_check = false;

      this.dialog = true;
    },
    async add_target() {
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("target", this.target);
      formData.append("year", this.dayjs(this.date).format("YYYY"));

      let { data } = await sales.post_targets(formData);
      if (data.msg == "ok") {
        this.get_user_target(this.id);
        // this.dialog = false;
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "ตั้งค่าสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        alert(data.msg);
      }
    },
    set_expand(status, text, type) {
      this.expand = {
        status: status,
        text: text,
        type: type,
      };
    },
    async get_user_target(uid) {
      let { data } = await sales.get_targets_user(uid);

      if (data.msg == "ok") {
        this.targets = data.result;
      } else {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: "ผิดพลาด" + " " + data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async update_Usertarget(target) {
      let formData = new FormData();
      formData.append("uid", this.id);
      formData.append("target", target);

      let { data } = await sales.put_Usertargets(formData);
      if (data.msg == "ok") {
        this.get_user();
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "ตั้งค่าสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: "ผิดพลาด" + " " + data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  display: inline;
}
p {
  display: inline;
}

.target_table {
  margin: 10px;
}
</style>
