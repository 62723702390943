<template>
  <div>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              label="ชื่อ"
              :rules="[rules.required]"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              v-model="form.position"
              label="ตำแหน่ง"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              v-model="form.tel"
              label="เบอร์ติดต่อ"
              :rules="[rules.required, rules.counter]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              v-model="form.email"
              label="อีเมล"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <v-checkbox @change="changeStatus" label="บุคคล"></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-autocomplete
              :rules="[rules.required]"
              :items="company"
              label="บริษัท"
              item-text="name"
              item-value="id"
              v-if="!checkbox"
              v-model="form.company_id"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="save()"> บันทึก </v-btn>
    </div>
  </div>
</template>

<script>
import company from "@/services/company";
import customer from "@/services/customers";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 10 || "Minimum 10 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      company: [],
      form: {
        name: "",
        position: "",
        tel: "",
        email: "",
        company_id: "",
      },
      checkbox: false,
    };
  },

  mounted() {
    this.getCompany();
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          formData.append(`${key}`, value);
        }
        let result = await customer.add_customer(formData);
        if (result.data.msg == "ok") {
          this.clear();
        }
      }
    },
    async getCompany() {
      let { data } = await company.get_company();
      this.company = data.result;
    },
    changeStatus() {
      if (this.checkbox == true) {
        this.checkbox = false;
      } else {
        this.checkbox = true;
        this.form.company_id = null;
      }
    },
    clear() {
      this.form = {
        name: "",
        position: "",
        tel: "",
        email: "",
        company_id: "",
      };
      this.$emit("dialog", false);
    },
  },
};
</script>

<style scoped>
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
