<template>
  <div>
    <apexchart
      el="app1"
      type="bar"
      :options="chartOptions"
      :series="series"
      max-width="500"
      height="350"
    ></apexchart>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: ["orders"],
  watch: {
    orders(newVal) {
      this.reset();
      this.createChart(newVal);
    },
  },
  data() {
    return {
      label: [],
      job_class: [],
      chartOptions: {
        chart: {
          id: "vuechart",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "เจ้า";
          //   },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
      },
      series: [
        {
          name: "รวมทั้งหมด",
          data: [],
        },
      ],
    };
  },

  methods: {
    createChart(data) {
      let status_class = [];
      data.forEach((element) => {
        status_class.push(
          dayjs(element.created).locale("th").format("DD/MMMM")
        );
      });

      let current = null;
      let cnt = 0;
      status_class.sort();
      for (let i = 0; i < status_class.length; i++) {
        if (status_class[i] != current) {
          if (cnt > 0) {
            this.label.push(cnt);
            this.job_class.push(current);
          }
          current = status_class[i];
          cnt = 1;
        } else {
          cnt++;
        }
      }
      if (cnt > 0) {
        this.label.push(cnt);
        this.job_class.push(current);
        this.chartOptions = {
          labels: this.job_class,
        };

        this.series = [
          {
            data: this.label,
          },
        ];
      }
    },
    reset() {
      this.label = [];
      this.job_class = [];
      this.chartOptions = {
        labels: [],
        colors: [],
      };
      this.series = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 200px;
    border-radius: 10px;
    height: 130px;
    padding: 30px;
    margin: 20px;
    line-height: 40px;
    color: white;
  }
}
</style>
