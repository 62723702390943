import httpClient from "@/services/httpClient";

const get_noti_now = async (id) => {
  return httpClient.get("notification" + `/id/${id}`);
};

const get_noti_list = async (data) => {
  return httpClient.post("notification", data);
};

const put_noti_list = async (data) => {
  return httpClient.put("notification", data);
};

const update_noti_list = async (data) => {
  return httpClient.post("notification_read", data);
};

export default {
  get_noti_now,
  get_noti_list,
  put_noti_list,
  update_noti_list,
};
