<template>
  <div>
    <apexchart
      el="app1"
      type="bar"
      :options="chartOptions"
      :series="series"
      max-width="500"
      height="350"
      v-if="date && date.length"
    ></apexchart>
  </div>
</template>

<script>
import dashboards from "@/services/dashboards";
import dayjs from "dayjs";
export default {
  props: ["date","uid"],
  watch: {
    date(newVal) {
      this.get_data(newVal);
    },
  },

  data() {
    return {
      label: [],
      job_class: [],
      chartOptions: {
        chart: {
          id: "vuechart",
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return dayjs(value).locale("th").format("DD/MMMM");
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "เจ้า";
          //   },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
      },
      series: [
        {
          name: "รวมทั้งหมด",
          data: [],
        },
      ],
    };
  },
  created() {
    this.get_data(this.date,this.uid);
  },
  methods: {
    async get_data(date,id) {
      let formData = new FormData();
      formData.append("date", date);
      formData.append("id", id);
      let { data } = await dashboards.get_orderToMonth(formData);
      let result = data.result;
      this.chartOptions = {
        labels: result.map((prod) => prod.date),
        theme: {
          palette: "palette1",
        },
      };

      this.series = [
        {
          data: result.map((prod) => prod.c),
        },
      ];
    },
    createChart(data) {
      let status_class = [];
      data.forEach((element) => {
        status_class.push(
          dayjs(element.created).locale("th").format("DD/MMMM")
        );
      });

      let current = null;
      let cnt = 0;
      status_class.sort();
      for (let i = 0; i < status_class.length; i++) {
        if (status_class[i] != current) {
          if (cnt > 0) {
            this.label.push(cnt);
            this.job_class.push(current);
          }
          current = status_class[i];
          cnt = 1;
        } else {
          cnt++;
        }
      }
      if (cnt > 0) {
        this.label.push(cnt);
        this.job_class.push(current);
        this.chartOptions = {
          labels: this.job_class,
        };

        this.series = [
          {
            data: this.label,
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 200px;
    border-radius: 10px;
    height: 130px;
    padding: 30px;
    margin: 20px;
    line-height: 40px;
    color: white;
  }
}
</style>
