<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ผู้ใช้งานระบบ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                เพิ่มผู้ใช้งาน
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">ผู้ใช้ใหม่</span>
              </v-card-title>

              <v-card-text>
                <AddUser @dialog="set_dailog" @snackbar="set_snackbar" />
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="Editdialog" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">แก้ไขลูกค้า</span>
              </v-card-title>

              <v-card-text>
                <EditUser
                  @dialog="set_editdailog"
                  :items="editedItem"
                  @snackbar="set_snackbar"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="300px">
            <v-card>
              <v-card-title class="text-h5">คุณต้องการลบหรือไม่</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDelete"
                  >ยกเลิก</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >ลบ</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
      <!--***************************************** Table ************************************************* -->
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.firstname }}</td>
          <td>
            {{ item.lastname }}
          </td>
          <td>
            {{ item.username }}
          </td>
          <td>
            <v-chip :color="getColor(item.rule_name)" dark>
              {{ item.rule_name }}
            </v-chip>
          </td>
          <td>{{ dayjs(item.lastlogin).format("DD/MM/BBBB") }}</td>
          <td>
            <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
            <v-icon class="mr-2" @click="Password(item)"> key </v-icon>
            <v-icon @click="deleteItem(item)"> delete </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <Alert :snackbars="snackbar" />

    <!-- Dialog Reset Password-->
    <v-dialog v-model="Passworddialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">แก้ไขรหัสผ่าน</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  label="New Password"
                  solo
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="confirmPassword"
                  :rules="[
                    (v) => !!v || 'field is required',
                    password === confirmPassword || 'Password must match',
                  ]"
                  label="Confirm Password"
                  solo
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updatePassword()"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddUser from "@/components/users/add_users.vue";
import EditUser from "@/components/users/edit_users.vue";
import users from "@/services/users";
import Alert from "@/components/alerts.vue";
export default {
  components: {
    AddUser,
    EditUser,
    Alert,
  },
  data: () => ({
    show1: false,
    show2: false,
    dialog: false,
    Editdialog: false,
    dialogDelete: false,
    Passworddialog: false,
    headers: [
      {
        text: "ชื่อ",
        align: "start",
        sortable: false,
        value: "firstname",
      },
      { text: "นามสกุล", value: "lastname" },
      { text: "Username", value: "username" },
      { text: "ระดับ", value: "rule_name" },
      { text: "lastlogin", value: "lastlogin" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    users: [],
    deleteId: "",
    editedIndex: -1,
    editedItem: {},
    snackbar: {},
    uid: "",

    password: "",
    confirmPassword: "",
    passwordRules: [
      (value) => !!value || "Please type password.",
      (value) => (value && value.length >= 6) || "minimum 6 characters",
    ],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.get_users();
  },

  methods: {
    set_snackbar(msg, color) {
      this.snackbar = {
        snackbar: true,
        text: msg,
        color: color,
      };
    },
    async get_users() {
      this.users = [];
      let result = await users.get_user();
      this.users = result.data.result;
    },

    set_dailog(data) {
      this.dialog = data;
      this.get_users();
    },

    set_editdailog(data) {
      this.Editdialog = data;
      this.get_users();
    },

    initialize() {
      this.users = [];
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = item;
      this.Editdialog = true;
    },

    deleteItem(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let result = await users.del_user(this.deleteId);
      if (result.data.msg == "ok") {
        this.closeDelete();
      }
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.get_users();
    },

    getColor(data) {
      if (data == "admin") return "red";
      else if (data == "manager") return "blue";
      else return "green";
    },

    Password(item) {
      this.uid = item.id;
      this.Passworddialog = true;
    },
    async updatePassword() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("uid", this.uid);
        formData.append("password", this.confirmPassword);
        let result = await users.put_password(formData);
        if (result.status == 200) {
          this.Passworddialog = false;
          this.set_snackbar("อัพเดตข้อมูลสำเร็จ", "green");
        } else {
          this.set_snackbar(result.data.msg, "green");
        }
      }
    },
  },
};
</script>

<style></style>
