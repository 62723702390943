<template>
  <div>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              label="ชื่อ"
              :rules="[rules.required]"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-file-input
              v-model="form.image"
              accept="image/*"
              label="Image"
              :rules="[rules.required]"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              :rules="[rules.required]"
              :items="category"
              label="หมวดหมู่"
              item-text="name"
              item-value="id"
              v-model="form.category_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-textarea
              v-model="form.detail"
              label="รายละเอียด"
              :rules="[rules.required]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="foolter-form">
      <v-btn color="blue darken-1" text @click="save()"> บันทึก </v-btn>
    </div>
  </div>
</template>

<script>
import product from "@/services/product";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 10 || "Minimum 10 characters",
      },
      category: [],
      form: {
        name: "",
        image: null,
        detail: "",
        category_id: "",
      },
    };
  },

  mounted() {
    this.getCategory();
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        const { name, detail, category_id } = this.form;

        formData.append("name", name);
        formData.append("detail", detail);
        formData.append("category", category_id);
        formData.append("image", this.form.image);

        let result = await product.add_product(formData);
        if (result.data.msg == "ok") {
          this.clear();
        } else {
        }
      }
    },
    async getCategory() {
      let result = await product.get_category();
      this.category = result.data.result;
    },
    clear() {
      this.form = {
        name: "",
        image: null,
        detail: "",
        category_id: "",
      };
      this.$emit("dialog", false);
    },
  },
};
</script>

<style scoped>
.foolter-form {
  display: flex;
  justify-content: end;
  flex-direction: row;
}
</style>
