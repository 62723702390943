import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";
import router from "@/router";

const isLoggedIn = () => {
  let token = localStorage.getItem("token");
  return token != null;
};

const logoff = () => {
  localStorage.removeItem("token");
  router.push("/login");
};

const login = async (values) => {
  let result = await httpClient.post(server.LOGIN_URL, values);

  if (result.statusText == "OK") {
    localStorage.setItem("username", result.data.user.username);
    localStorage.setItem("token", result.data.token);
    localStorage.setItem("rule", result.data.user.rule);
    localStorage.setItem("firstname", result.data.user.firstname);
    localStorage.setItem("id", result.data.user.id);
    if (result.data.user.rule == 1) {
      router.push("/");
    }
    if (result.data.user.rule == 2) {
      router.push("/dashboard/manager");
    }
    if (result.data.user.rule == 3) {
      router.push("/dashboard");
    }
    return result.data;
  } else {
    return false;
  }
};

export default {
  login,
  isLoggedIn,
  logoff,
};
