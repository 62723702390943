import httpClient from "@/services/httpClient";

const get_targets_user = async (id) => {
  return httpClient.get("target" + `/id/${id}`);
};

const post_targets = async (data) => {
  return httpClient.post("target", data);
};

const check_targets = async (data) => {
  return httpClient.get("check_target" + `/data/${data}`);
};

const get_targets = async () => {
  return httpClient.get("target_user");
};

const put_Usertargets = async (data) => {
  return httpClient.put("update_Usertarget", data);
};

export default {
  get_targets,
  post_targets,
  check_targets,
  get_targets_user,
  put_Usertargets,
};
