<template>
  <div>
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
      max-width="400"
      v-if="data && data.length"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: ["data"],
  watch: {
    data(val){
      this.generate(val);
      // console.log(val)
    }
  },
  data() {
    return {
      label: [],
      job_class: [],
      chartOptions: {
        labels: [],
        theme: {
            palette: "palette1",
          },
      },
      series: [],
    };
  },
  methods: {
    generate(data){
      // data.forEach(el => {
      //   if (el.name != "ทั้งหมด") {
      //   this.job_class.push(el.name);
      //   this.label.push(el.total) 
      //   }
      //   });
      let result =data;
       this.chartOptions = {
          labels: result.filter((prod) => prod.name != "ทั้งหมด").map((prod) => prod.name),
        };
        this.series = result.filter((prod) => prod.name != "ทั้งหมด").map((prod) => prod.total);
    },
    reset() {
      this.label = [];
      this.job_class = [];
      this.chartOptions = {
        labels: [],
      };
      this.series = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 200px;
    border-radius: 10px;
    height: 130px;
    padding: 30px;
    margin: 20px;
    line-height: 40px;
    color: white;
  }
}
</style>
