import httpClient from "@/services/httpClient";

const get_category = async () => {
  return httpClient.get("category");
};

const add_product = async (data) => {
  return httpClient.post("product", data);
};

const get_product = async () => {
  return httpClient.get("product");
};

const deleteProduct = (id) => {
  return httpClient.delete("product" + `/id/${id}`);
};

const update_product = async (data) => {
  return httpClient.put("product", data);
};

const get_productBycategody = async (id) => {
  return httpClient.get("product" + `/id/${id}`);
};

const update_category = async (data) => {
  return httpClient.put("category", data);
};

const add_category = async (data) => {
  return httpClient.post("category", data);
};

const delete_category = (id) => {
  return httpClient.delete("category" + `/id/${id}`);
};

const get_productByOrder = async (id) => {
  return httpClient.get("product" + `/order/${id}`);
};

export default {
  get_category,
  add_product,
  get_product,
  deleteProduct,
  update_product,
  get_productBycategody,
  update_category,
  add_category,
  delete_category,
  get_productByOrder,
};
