<template>
  <div class="background-view">
    <v-container>

      <div class="counter" v-if="totals && totals.length && !$vuetify.breakpoint.mobile" >
        <div v-for="item in totals" :key="item.index">
          <div class="element-counter">
            <h1>{{ item.total }}</h1>
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>

      <div class="Setdate">
        <Dateset @dateChange="dateChange"/>
      </div>
      <Targets :key="componentKey" />
      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <div class="chart">
              <h2>สถานะงาน</h2>
              <Pie_chart :data="totals" />
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <div class="chart">
              <h2>งานใหม่เพิ่มวันนี้</h2>
              <Worktable
                :uid="uuid"
                @renew="get_orderBysale"
                :key="componentKey"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <div class="chart">
              <h2>งานที่ขาดการอัพเดต</h2>
              <Worklate
                :uid="uuid"
                @renew="get_orderBysale"
                :key="componentKey"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <div class="chart">
              <h2>สินค้าที่ขาย</h2>
              <Pie_product :date="datetime"/>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <div class="chart">
              <h2>ช่องทางการขาย</h2>
              <Channel_report :date="datetime" :uid="uuid" />
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <div class="chart">
              <div>
                <h2>รายงานประจำเดือน</h2>
              </div>
              <Month_report :date="datetime" :uid="uuid" />
            </div>
          </v-col>
        </v-row>
      </div>
      <br />
    </v-container>
  </div>
</template>

<script>
import Counters from "@/components/dashboard/counter.vue";
import Pie_chart from "@/components/dashboard/pie_chart_counter.vue";
import Pie_Saleman from "@/components/dashboard/pie_chart_user.vue";
import Pie_product from "@/components/dashboard/users/pie_chart_product.vue";
import Worktable from "@/components/dashboard/users/table_work_user.vue";
import Worklate from "@/components/dashboard/users/table_work_late.vue";
import Month_report from "@/components/dashboard/mounth_report_user.vue";
import Sale_report from "@/components/dashboard/sale_report.vue";
import Channel_report from "@/components/dashboard/channel_report.vue";
import dashboards from "@/services/dashboards";
import Targets from "@/components/dashboard/users/target.vue";
import Dateset from '@/components/set_date.vue'

export default {
  name: "home",
  components: {
    Counters,
    Pie_chart,
    Worktable,
    Pie_Saleman,
    Month_report,
    Sale_report,
    Channel_report,
    Pie_product,
    Worklate,
    Targets,
    Dateset
  },
  data() {
    return {
      orders: [],
      seller: [],
      product: [],
      totals: [],
      selectedItem: 0,
      orderBysale: [],
      uuid: "",
      componentKey: 0,
      datetime: null,
    };
  },
  created() {
    this.datetime = localStorage.getItem('QueryDate');
    this.uuid = localStorage.getItem("id");
    this.get_data();
  },
  mounted() {
    // this.componentKey += 1;
    // this.get_orderBysale();
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    async get_data() {
      try {
        let formData = new FormData();
        formData.append("date", this.datetime);
        formData.append("id", localStorage.getItem("id"));
        let { data } = await dashboards.get_counter_order(formData);
        this.totals = data.result;
      //   if (this.componentKey != 1) {
      //   this.forceRerender();
      // }
      } catch (error) {
        console.log(error)
      }
    },
    async get_orderBysale() {
      let result = await dashboards.get_orderBysale(localStorage.getItem("id"));
      this.orders = result.data.result;
      if (this.componentKey != 1) {
        console.log(this.componentKey);
        this.forceRerender();
      }
    },
    async dateChange(){
      this.$router.go();
  },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item-group .v-list-item--active {
  color: #ff3c3c !important;
  font-weight: bold;
}
.background-view {
  height: 100%;
  background-color: rgb(248, 248, 248);
}
.container {
  padding: 50px;
}
.chart_data {
  margin-top: 30px;
}
.chart {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}
.Setdate{
  display: flex;
  flex-direction:row;
  justify-content:center;
}
.counter {
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.element-counter {
  background-color: #68b7ff;
  cursor: pointer;
  width: 20vh;
  height: 20vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
}
</style>
