<template>
  <div class="counter">
    <div v-for="item in totals" :key="item.index">
      <div
        class="element-counter">
        <h1>{{ item.total }}</h1>
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import dashboards from "@/services/dashboards";
export default {
  props: ["date"],
  data() {
    return {
      totals:[]
    };
  },

  watch: {
    date(newdata) {
      console.log(newdata);
      
    },
  },
  mounted() {
    this.get_data(this.date);
    
  },

  methods: {
   async get_data(date){
    console.log(date)
    let formData = new FormData();
    if (this.$store.getters['rule'] == 1) {
    formData.append("date", date);
     let {data} = await dashboards.get_counter_order(formData);
     console.log(data.result);
     this.totals = data.result;
    }  
   },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.element-counter {
  background-color: #68b7ff;
  cursor: pointer;
  width: 20vh;
  height: 20vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
}

@media screen and (max-width: 736px) {
  .counter {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px;
  }
}

@media screen and (max-width: 736px) {
  .counter {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 26px;
  }
}
</style>
