<template>
  <div>
    <v-simple-table height="36vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">date</th>
            <th class="text-left">company</th>
            <!-- <th class="text-left">customer</th> -->
            <!-- <th class="text-left">channel</th> -->
            <th class="text-left">product</th>
            <th class="text-left">detail</th>
            <th class="text-left">sale person</th>
            <th class="text-left">status</th>
            <th class="text-left">action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in orders" :key="item.name" class="table-click">
            <td>{{ dayjs(item.created).format("DD/MM/BBBB") }}</td>
            <td>{{ item.company_name }}</td>

            <!-- <td>{{ item.customer_name }}</td> -->
            <!-- <td>
            <v-chip text-color="white" :color="item.color">{{
              item.CHANNEL
            }}</v-chip> -->
            <!-- </td> -->
            <td>{{ item.product }}</td>
            <td>{{ item.detail }}</td>
            <td>
              <v-chip>{{ item.firstname }}</v-chip>
            </td>
            <td>
              <v-chip text-color="white" :color="item.status_color">{{
                item.status
              }}</v-chip>
            </td>
            <td>
              <v-icon class="mr-2" @click="updateItem(item)">
                add_comment
              </v-icon>
              <v-icon class="mr-2" @click="comment(item)"> comment </v-icon>
              <v-icon @click="view_data(item)"> description </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- UpdateStatus -->
    <v-dialog v-model="dialog_update" max-width="1000">
      <v-card>
        <v-card-title class="text-h5">Status Update</v-card-title>
        <v-simple-table class="mr-4 ml-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">datetime</th>
                <th class="text-left">status</th>
                <th class="text-left">detail</th>
                <th class="text-left">file</th>
                <th class="text-left">user</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in status" :key="item.name">
                <td>{{ dayjs(item.date).format("DD-MM-BBBB") }}</td>
                <td>
                  <v-chip :color="item.color" text-color="white">
                    {{ item.status }}
                  </v-chip>
                </td>
                <td>{{ item.detail }}</td>
                <td>
                  <div v-if="item.file != null">
                    <v-chip
                      class="mr-1 mb-1"
                      close
                      color="primary"
                      text-color="white"
                      v-for="data in item.file.split(',')"
                      :key="data.index"
                      close-icon="mdi-download-circle"
                      @click:close="download(data)"
                    >
                      {{ data | truncate(10) }}
                    </v-chip>
                  </div>
                </td>
                <td>{{ item.user }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br />
        <div class="mr-5 ml-5">
          <v-row class="formUpdate">
            <v-form ref="form">
              <v-col cols="12" md="12" sm="12">
                <v-select
                  :rules="[rules.required]"
                  :items="all_status"
                  item-text="name"
                  item-value="id"
                  label="status"
                  v-model="form.status"
                ></v-select>
              </v-col>
              <!-- Target -->
              <v-col cols="12" md="12" sm="12" v-if="form.status == 5">
                <center>
                  <h2>
                    {{
                      form.price
                        | currency("฿", 2, {
                          spaceBetweenAmountAndSymbol: true,
                        })
                    }}
                  </h2>
                </center>
                <br />
                <v-text-field
                  label="ยอดขาย"
                  hint="กรอกเฉพาะตัวเลยเท่านั้น"
                  persistent-hint
                  v-model="form.price"
                  outlined
                  prefix="฿"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  :rules="[rules.required, rules.counter]"
                  outlined
                  counter
                  v-model="form.content"
                  label="detail"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-file-input
                  show-size
                  chips
                  multiple
                  label="File input"
                  v-model="files"
                ></v-file-input>
              </v-col>
            </v-form>
          </v-row>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog_update = false">
            ยกเลิก
          </v-btn>

          <v-btn color="green darken-1" text @click="updateconfirm()">
            อัพเดต
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Comment -->
    <v-dialog v-model="dialog_comment" max-width="800">
      <v-card>
        <v-card-title class="text-h5"> Note </v-card-title>
        <div class="ma-5 myBox" v-if="comment_order.length != 0">
          <div
            class="comment pa-5 ma-3"
            v-for="item in comment_order"
            :key="item.index"
          >
            <v-row class="pa-4 comment-text">
              <p>{{ item.detail }}</p>
            </v-row>
            <v-row class="comment_detail">
              <v-col cols="12" md="8" sm="6">
                <div v-if="item.file != null">
                  <v-chip
                    class="mr-1 mb-1"
                    close
                    color="primary"
                    text-color="white"
                    small
                    v-for="data in item.file.split(',')"
                    :key="data.index"
                    close-icon="mdi-download-circle"
                    @click:close="download_comment(data)"
                  >
                    {{ data | truncate(20) }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <h4>วันที่ :</h4>
                <p>
                  {{ dayjs(item.created).format("DD-MM-BBBB") }} |
                  {{ dayjs(item.created).format("HH:mm:ss") }}
                </p>
                <br />
                <h4>ชื่อ :</h4>
                <p>{{ item.firstname }}</p>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="ma-5">
          <v-row class="formUpdate">
            <v-form ref="form">
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  :rules="[rules.required, rules.counter]"
                  outlined
                  counter
                  v-model="comments.detail"
                  label="detail"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-file-input
                  show-size
                  chips
                  multiple
                  label="File input"
                  v-model="comments.files"
                ></v-file-input>
              </v-col>
            </v-form>
          </v-row>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="dialog_comment = false">
              ยกเลิก
            </v-btn> -->
          <v-btn
            color="success"
            class="mb-2 white--text"
            @click="comment_add()"
          >
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dashboards from "@/services/dashboards";
import order from "@/services/orders";
import setting from "@/services/setting";
import axios from "axios";
import { orderUrl } from "@/services/constants";

export default {
  props: ["users"],
  watch: {
    users(newVal) {
      this.user_save = newVal;
      this.getOrderToday(newVal);
    },
  },
  data() {
    return {
      user_save: null,
      dialog_update: false,
      orders: [],
      status: [],
      update_id: "",
      comment_id: "",
      files: [],
      comment_order: [],
      all_status: [],
      form: {
        status: "",
        content: "",
        price: 0,
      },
      rules: {
        required: (value) => !!value || "Required.",
        size: (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
        counter: (value) => value.length <= 200 || "Minimum 10 characters",
      },
      dialog_comment: false,
      comments: {
        detail: "",
        files: [],
      },
      comment_order: [],
    };
  },
  mounted() {
    this.get_status();
  },
  methods: {
    async getOrderToday(users) {
      let formData = new FormData();
      formData.append("user", JSON.stringify(users));

      let result = await dashboards.get_order_manager_last(formData);
      this.orders = result.data.result;
    },

    view_data(item) {
      let routeData = this.$router.resolve(`/view/${item.order_id}`);
      window.open(routeData.href, "_blank");
    },

    async updateItem(item) {
      this.update_id = item.order_id;
      let result = await order.get_orderstatus(item.order_id);
      if (result.data.msg == "ok") {
        this.status = result.data.result;
      }
      this.dialog_update = true;
    },

    async updateconfirm() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("customer_status", this.form.status);
        formData.append("detail", this.form.content);
        formData.append("id", this.update_id);
        formData.append("price", this.form.price);
        formData.append("user", localStorage.getItem("id"));

        if (this.files.length !== 0) {
          for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            formData.append("files[" + i + "]", file);
          }
        }

        let result = await order.update_status(formData);
        if (result.data.msg == "ok") {
          this.clear();
        } else if (result.data.msg == "error") {
          alert(result.data.result);
        }
      }
    },

    async get_status() {
      let result = await setting.get_status();
      this.all_status = result.data.status;
    },

    download(file) {
      axios
        .get(`${orderUrl}/download/file/${file}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    download_comment(file) {
      axios
        .get(`${orderUrl}/download/file_cmoment/${file}`, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    async clear() {
      this.form = {
        status: "",
        content: "",
        price: 0,
      };

      await this.getOrderToday(this.user_save);
      let result = await order.get_orderstatus(this.update_id);
      this.$emit("renew", this.user_save);
      this.update_id = "";
      this.files = [];
      if (result.data.msg == "ok") {
        this.status = result.data.result;
      }
    },

    // ***************************Comment Service *******************************

    async get_comment(id) {
      let result = await order.get_ordercomment(id);
      this.comment_order = await result.data.result;
      this.dialog_comment = true;
    },

    async comment(item) {
      this.comment_id = item.order_id;
      this.get_comment(item.order_id);
    },

    async comment_add() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("detail", this.comments.detail);
        formData.append("id", this.comment_id);
        formData.append("user", localStorage.getItem("id"));

        if (this.comments.files.length !== 0) {
          for (let i = 0; i < this.comments.files.length; i++) {
            let file = this.comments.files[i];
            formData.append("files[" + i + "]", file);
          }
        }
        let result = await order.update_comment(formData);
        if (result.data.msg == "ok") {
          // this.dialog_comment = false;
          this.get_comment(this.comment_id);
          this.comments = {
            detail: "",
            files: [],
          };
        } else if (result.data.msg == "error") {
          alert(result.data.result);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-click {
  cursor: pointer !important;
}

.formUpdate {
  display: grid;
}

.comment_detail h4 {
  font-size: 13px;
  display: inline;
}
.comment_detail p {
  font-size: 13px;
  display: inline;
}
.comment {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 20px;
}

.comment-text {
  background: #eceeef;
  border-radius: 10px;
}
.myBox {
  border: none;
  padding: 5px;
  width: 97%;
  height: 450px;
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #1793c5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4c5cd0;
}
</style>
