//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

// export const apiUrl = "https://takeco.online:4004/api/v2";
// export const imageUrl = "https://takeco.online:4004/images";
// export const orderUrl = "https://takeco.online:4004/api/v2";

export const apiUrl = process.env.VUE_APP_API_URL;
export const imageUrl = process.env.VUE_APP_IMAGE_URL;
export const orderUrl = process.env.VUE_APP_ORDER_URL;
export const profileUrl = process.env.VUE_APP_PROFILE_URL;

export const server = {
  LOGIN_URL: `login`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `product`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `report`,
  TOKEN_KEY: `token`,
  USERNAME: `username`,
  CHANNEL_URL: `channel`,
};
