<template>
  <div>
    <v-row>
      <v-spacer> </v-spacer>
      <v-btn
        color="primary"
        dark
        class="mr-4 mb-5"
        @click="dialog_insert = true"
      >
        New Status
      </v-btn>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="channel"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <!-- <td>{{ item.id }}</td> -->
          <td>{{ item.name }}</td>
          <td>
            <v-chip class="ma-2" :color="item.color" text-color="white">
              {{ item.color }}
            </v-chip>
          </td>
          <td>
            <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
            <span class="ma-1"></span>
            <v-icon @click="delItem(item)"> delete </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- dailog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    label="id"
                    readonly
                    v-model="form.id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    label="name"
                    hint="facebook,line"
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <center>
                    <v-color-picker
                      :rules="[rules.required]"
                      dot-size="25"
                      swatches-max-height="200"
                      mode="hexa"
                      v-model="form.color"
                    ></v-color-picker>
                  </center>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ปิด
          </v-btn>
          <v-btn color="blue darken-1" text @click="update()"> อัพเดต </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dailog Insert-->
    <v-dialog v-model="dialog_insert" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Insert</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    :rules="[rules.required]"
                    label="name"
                    hint="facebook,line"
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <center>
                    <v-color-picker
                      :rules="[rules.required]"
                      dot-size="25"
                      swatches-max-height="200"
                      mode="hexa"
                      v-model="form.color"
                    ></v-color-picker>
                  </center>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_insert = false">
            ปิด
          </v-btn>
          <v-btn color="blue darken-1" text @click="insert()">เพิ่ม</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dailog Delete-->
    <v-dialog v-model="dialog_del" persistent max-width="200">
      <v-card>
        <v-card-title class="text-h5"> Alret </v-card-title>
        <v-card-text>คุณต้องการลบหรือไม่</v-card-text>
        <v-card-actions class="ml-3">
          <v-btn color="green darken-1" text @click="dialog_del = false">
            ปิด
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteitem()"> ลบ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import setting from "@/services/setting";
export default {
  data: () => ({
    dialog: false,
    dialog_insert: false,
    dialog_del: false,
    channel: [],
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length >= 10 || "Minimum 10 characters",
    },
    headers: [
      // {
      //   text: "id",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "name", value: "name" },
      { text: "color", value: "color" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    form: {
      id: "",
      name: "",
      color: "",
    },
    del_id: "",
  }),
  mounted() {
    this.get_status();
  },
  methods: {
    async get_status() {
      let result = await setting.get_status();
      this.channel = result.data.status;
    },
    editItem(item) {
      this.form = item;
      this.dialog = true;
    },
    delItem(item) {
      this.del_id = item.id;
      this.dialog_del = true;
    },
    async update() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        const { name, id, color } = this.form;

        formData.append("id", id);
        formData.append("name", name);
        formData.append("color", color);
        let result = await setting.put_status(formData);
        if (result.data.msg == "ok") {
          this.get_status();
          this.dialog = false;
        }
      }
    },
    async insert() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        const { name, color } = this.form;
        formData.append("name", name);
        formData.append("color", color);
        let result = await setting.add_status(formData);
        if (result.data.msg == "ok") {
          this.get_status();
          this.dialog_insert = false;
        } else if (result.data.msg == "error") {
          alert(result.data.msg);
        }
      }
    },
    async deleteitem() {
      let result = await setting.del_status(this.del_id);
      if (result.data.msg == "ok") {
        this.get_status();
        this.dialog_del = false;
      } else if (result.data.msg == "error") {
        alert(result.data.msg);
      }
    },
  },
};
</script>

<style></style>
