<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :value="true"
      absolute
      right
      shaped
      top
      :color="color"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "snackbar_alert",
  props: ["snackbars"],
  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
    };
  },

  watch: {
    snackbars(newVal) {
      this.snackbar = newVal.snackbar;
      this.text = newVal.text;
      this.color = newVal.color;
    },
  },
};
</script>
