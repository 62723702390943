<template>
  <v-data-table
    :headers="headers"
    :items="company"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>บริษัทลูกค้า</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              เพิ่มบริษัท
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">เพิ่มบริษัทใหม่</span>
            </v-card-title>

            <v-card-text>
              <AddCompany @dialog="set_dailog" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editDialog" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="text-h5">แก้ไขข้อมูล</span>
            </v-card-title>

            <v-card-text>
              <EditCompany @dialog="set_editdailog" :editItem="editedItem" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="300px">
          <v-card>
            <v-card-title class="text-h5"
              ><center>ต้องการลบข้อมูลหรือไม่?</center>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDelete"
                >ยกเลิก</v-btn
              >
              <v-btn color="red darken-1" text @click="deleteItemConfirm"
                >ลบ</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
    <!--***************************************** Table ************************************************* -->
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.name }}</td>
        <td>
          {{ item.detail }}
        </td>
        <td>
          {{ item.regis_capital | currency("฿", 0, { symbolOnLeft: false }) }}
        </td>
        <td>{{ item.name_th }}</td>
        <td>{{ item.tel }}</td>
        <td>
          <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
          <span class="ma-1"></span>
          <v-icon @click="deleteItem(item)"> delete </v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import AddCompany from "@/components/company/add_company.vue";
import EditCompany from "@/components/company/edit_company.vue";
import company from "@/services/company";
export default {
  components: {
    AddCompany,
    EditCompany,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    editDialog: false,
    headers: [
      {
        text: "ชื่อ",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "รายละเอียด", value: "detail" },
      { text: "ทุนจดทะเบียน", value: "regis_capital" },
      { text: "จังหวัด", value: "name_th" },
      { text: "เบอร์โทร", value: "tel" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    company: [],
    editedIndex: -1,
    del_id: "",
    editedItem: null,
  }),
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.get_company();
  },

  methods: {
    async get_company() {
      this.company = [];
      let result = await company.get_company();
      // console.log(result.data.result);
      this.company = result.data.result;
    },
    set_dailog(data) {
      this.dialog = data;
      this.get_company();
    },
    initialize() {
      this.company = [];
    },

    editItem(item) {
      this.editedItem = item;
      this.editDialog = true;
    },
    set_editdailog(data) {
      this.editDialog = data;
      this.get_company();
    },

    deleteItem(item) {
      this.del_id = item.id + "_" + item.address_id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let result = await company.del_company(this.del_id);
      if (result) {
        this.closeDelete();
        this.get_company();
      } else {
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style></style>
