<template>
  <div class="container">
    <center>
      <v-btn
        rounded
        color="primary"
        class="mb-4 ml-4"
        dark
        @click.prevent="dialog = true"
      >
        Add manager
      </v-btn>
    </center>
    <br />
    <v-row>
      <v-col cols="12" md="6" sm="6">
        <v-card class="mx-auto rounded-lg" max-width="400">
          <v-list rounded>
            <v-subheader>Manager</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in manager"
                :key="i"
                @click="selected(item)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.firstname + " " + item.lastname
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card></v-col
      >
      <v-col cols="12" md="6" sm="6">
        <v-card class="mx-auto rounded-lg" max-width="400">
          <v-list rounded>
            <v-subheader>Users</v-subheader>

            <v-list-item v-for="(item, i) in show_user" :key="i">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.firstname + " " + item.lastname
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon @click.prevent="deleteItem(item)">mdi-close</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card></v-col
      >
    </v-row>

    <!-- Dialog -->

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">ADD Manager</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    :rules="[rules.required]"
                    :items="manager"
                    item-value="id"
                    v-model="form.manager_id"
                    @change="get_userOfmanager()"
                    dense
                    filled
                    label="manager"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.firstname }} {{ data.item.lastname }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.firstname }} {{ data.item.lastname }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    :rules="[rules.required]"
                    v-model="form.users_id"
                    :items="real_user"
                    item-value="id"
                    return-object
                    chips
                    small-chips
                    label="user"
                    multiple
                    filled
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip small class="ma-2" color="primary"
                        >{{ data.item.firstname }} {{ data.item.lastname }}
                      </v-chip>
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.firstname }} {{ data.item.lastname }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clear()"> ปิด </v-btn>
          <v-btn color="blue darken-1" text @click="manager_add()">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import users from "@/services/users";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 10 || "Minimum 10 characters",
      },
      manager: [],
      select_id: "",
      dialog: false,
      form: {
        manager_id: "",
        users_id: [],
      },
      users: [],
      real_user: [],
      show_user: [],
    };
  },

  mounted() {
    this.get_manager();
    this.get_users();
  },

  methods: {
    async get_manager() {
      let result = await users.get_manager();
      if ((result.data.msg = "ok")) {
        this.manager = result.data.result;
      }
    },
    async get_users() {
      let result = await users.get_userSale();
      if ((result.data.msg = "ok")) {
        this.users = result.data.result;
      }
    },
    async selected(item) {
      if (this.select_id.length == 0) {
        this.select_id = item.id;
        let result = await users.get_userOfmanager(item.id);
        if (result.data.result.length != 0) {
          this.show_user = result.data.result;
        } else if (result.data.result.length == 0) {
          this.show_user = [];
        }
      } else {
        if (this.select_id == item.id) {
          this.select_id = "";
          this.show_user = [];
        } else {
          this.select_id = item.id;
          let result = await users.get_userOfmanager(item.id);
          if (result.data.result.length != 0) {
            this.show_user = result.data.result;
          } else if (result.data.result.length == 0) {
            this.show_user = [];
          }
        }
      }
    },
    async get_userOfmanager() {
      if (this.$refs.form.validate()) {
      }
      let result = await users.get_userOfmanager(this.form.manager_id);
      if (result.data.result.length != 0) {
        let resultA = this.users.filter(
          (elm) =>
            !result.data.result
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        );

        let resultB = result.data.result.filter(
          (elm) =>
            !this.users
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        );
        this.real_user = [...resultA, ...resultB];
      } else if (result.data.result.length == 0) {
        this.real_user = this.users;
      }
    },
    async manager_add() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("manager", this.form.manager_id);
        formData.append("user", JSON.stringify(this.form.users_id));

        let result = await users.add_userManager(formData);
        if (result.data.msg == "ok") {
          this.clear();
        } else if (result.data.msg == "error") {
          alert(result.data.result);
        }
      }
    },
    async deleteItem(item) {
      // console.log(item.id);
      // console.log(this.select_id);
      let id = `${item.id},${this.select_id}`;

      let result = await users.del_userOfmanager(id);
      if (result.data.msg == "ok") {
        let results = await users.get_userOfmanager(this.select_id);
        this.show_user = results.data.result;
      } else if (result.data.msg == "error") {
        alert(result.data.result);
      }
    },
    clear() {
      this.dialog = false;
      this.form = {
        manager_id: "",
        user_id: "",
      };
      this.real_user = [];
      this.show_user = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item-group .v-list-item--active {
  color: #0f5dd1 !important;
  font-weight: bold;
}
</style>
