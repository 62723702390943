import httpClient from "@/services/httpClient";

const add_customer = async (values) => {
  return httpClient.post("customer", values);
};

const get_customer = async () => {
  return httpClient.get("customer");
};

const del_customer = (id) => {
  return httpClient.delete("customer" + `/id/${id}`);
};

const put_customer = async (data) => {
  return httpClient.put("customer", data);
};

const get_customer_company = (id) => {
  return httpClient.get("customer" + `/company/${id}`);
};

export default {
  add_customer,
  get_customer,
  del_customer,
  put_customer,
  get_customer_company,
};
