<template>
  <div class="target_table">
    <h2>ยอดขายรวม</h2>
    <br />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ชื่อ</th>
            <th class="text-left">เป้าการขาย/เดือน</th>
            <th class="text-left">ยอดขายจริง</th>
            <th class="text-left">เปอร์เซนต์</th>
            <th class="text-left">ยอดที่ขาด/เกิน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in reports" :key="item.name">
            <td>{{ item.firstname }} {{ item.lastname }}</td>
            <td>
              <v-chip class="ma-2" color="primary" v-if="item.month != null">
                {{
                  item.month
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </v-chip>
            </td>
            <td>
              <v-chip
                class="ma-2"
                color="orange"
                text-color="white"
                v-if="item.actual != null"
              >
                {{
                  item.actual
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </v-chip>
            </td>
            <td>
              <div v-if="item.Achive != null">{{ item.Achive }} %</div>
            </td>
            <td>
              <p
                style="display: inline; color: red"
                v-if="item.actual < item.month"
              >
                -
              </p>
              <p
                style="display: inline; color: green"
                v-if="item.actual > item.month"
              >
                +
              </p>
              <p
                style="display: inline; color: red"
                v-if="item.actual < item.month"
              >
                {{
                  (item.month - item.actual)
                    | currency("฿", 0, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </p>
              <p
                style="display: inline; color: green"
                v-if="item.actual > item.month"
              >
                {{
                  (item.actual-item.month)
                    | currency("฿", 0, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import report from "@/services/reports";
export default {
  data() {
    return {
      reports: [],
      date: "",
      menu: false,
      month: "",
    };
  },
  mounted() {
    (this.date = new Date().toISOString().substr(0, 7)), this.get_sum_targets();
  },
  methods: {
    async get_sum_targets() {
      let { data } = await report.report_target_manager(
        localStorage.getItem("id")
      );
      if (data.msg == "ok") {
        this.reports = data.result;
      }
    },
    async get_sumby_month() {
      this.month = this.date;
      let { data } = await report.get_bymonth_report_target(
        this.date.split("-")[1].slice(1)
      );
      if (data.msg == "ok") {
        this.reports = data.result;
        this.menu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.target_table {
  margin-top: 20px;
}
.v-text-field {
  width: 400px;
}
.select_month {
  margin-top: 10px;
}
</style>
