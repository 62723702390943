import httpClient from "@/services/httpClient";

const get_counter_order = async (data) => {
  return httpClient.post("count_order", data);
};

const get_counter_product = async (data) => {
  return httpClient.post("count_product", data);
};

const get_counter_user = async (data) => {
  return httpClient.post("count_order_user", data);
};

const get_counter_channel = async (data) => {
  return httpClient.post("count_order_channel", data);
};

const get_orderToday = async () => {
  return httpClient.get("order/day");
};

const get_orderToMonth = async (data) => {
  return httpClient.post("order/month", data);
};

const get_orderBysale = async (uid) => {
  return httpClient.get("order" + `/sale/${uid}`);
};

const get_orderToMonth_user = async (uid) => {
  return httpClient.get("order" + `/month/${uid}`);
};

const get_orderToday_user = async (uid) => {
  return httpClient.get("order" + `/day/${uid}`);
};

const get_orderToday_userlate = async (uid) => {
  return httpClient.get("order" + `/late/${uid}`);
};

const get_orderToday_adminlate = async () => {
  return httpClient.get("order/late");
};

const get_order_ofmanager = async (data) => {
  return httpClient.post("order/manager", data);
};

const get_order_manager_last = async (data) => {
  return httpClient.post("order/manager_last", data);
};

const get_order_manager_late = async (data) => {
  return httpClient.post("order/manager_late", data);
};

const get_order_manager_month = async (data) => {
  return httpClient.post("order/manager_month", data);
};

export default {
  get_orderToday,
  get_orderToMonth,
  get_orderBysale,
  get_orderToMonth_user,
  get_orderToday_user,
  get_orderToday_userlate,
  get_orderToday_adminlate,
  get_order_ofmanager,
  get_order_manager_last,
  get_order_manager_late,
  get_order_manager_month,
  get_counter_order,
  get_counter_product,
  get_counter_user,
  get_counter_channel
};
