<template>
  <div class="container">
    <h1>ยอดขายรวม</h1>
    <div class="select_month">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="month"
            max-width="290px"
            label="เดือนที่ต้องการดู"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
          locale="th"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">ยกเลิก </v-btn>
          <v-btn text color="primary" @click="get_sumby_month()"> เลือก </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ชื่อ</th>
            <th class="text-left">เป้าการขาย</th>
            <th class="text-left">เป้าการขาย/เดือน</th>
            <th class="text-left">ยอดขายจริง</th>
            <th class="text-left">เปอร์เซนต์</th>
            <th class="text-left">ยอดที่ขาด/เกิน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in reports" :key="item.name">
            <td>{{ item.firstname }} {{ item.lastname }}</td>
            <td>
              <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="item.target != null"
              >
                {{
                  item.target
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </v-chip>
            </td>
            <td>
              <v-chip class="ma-2" color="primary" v-if="item.month != null">
                {{
                  item.month
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </v-chip>
            </td>
            <td>
              <v-chip
                class="ma-2"
                color="orange"
                text-color="white"
                v-if="item.actual != null"
              >
                {{
                  item.actual
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </v-chip>
            </td>
            <td>
              <div v-if="item.Achive != null">{{ item.Achive }} %</div>
            </td>
            <td>
              <p
                style="display: inline; color: red"
                v-if="item.actual < item.month"
              >
                -
              </p>
              <p
                style="display: inline; color: green"
                v-if="item.actual > item.month"
              >
                +
              </p>
              <p
                style="display: inline; color: red"
                v-if="item.actual < item.month"
              >
                {{
                  (item.month - item.actual)
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </p>
              <p
                style="display: inline; color: green"
                v-if="item.actual > item.month"
              >
                {{
                  ( item.actual-item.month)
                    | currency("฿", 2, {
                      spaceBetweenAmountAndSymbol: true,
                    })
                }}
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import report from "@/services/reports";
export default {
  data() {
    return {
      reports: [],
      date: "",
      menu: false,
      month: "",
    };
  },
  mounted() {
    this.date = new Date().toISOString().substr(0, 7);
    this.month = this.date;
    this.get_sum_targets(this.date);
  },
  methods: {
    async get_sum_targets(date) {
      let formData = new FormData();
      formData.append("date", date);
      let { data } = await report.get_all_report_target(formData);
      if (data.msg == "ok") {
        this.reports = data.result;
      }
    },
    async get_sumby_month() {
      this.month = this.date;
      let formData = new FormData();
      formData.append("date", this.date);
      let { data } = await report.get_all_report_target(formData);
      if (data.msg == "ok") {
        this.reports = data.result;
        this.menu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 30px;
}
.v-text-field {
  width: 400px;
}
.select_month {
  margin-top: 10px;
}
</style>
