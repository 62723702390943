<template>
  <div>
    <v-container>
      <v-tabs vertical>
        <v-tab>
          <v-icon left> mdi-account-cog </v-icon>
          โปรไฟล์
        </v-tab>
        <v-tab>
          <v-icon left> mdi-account-group </v-icon>
          ลูกค้า
        </v-tab>
        <v-tab v-if="$store.getters['rule'] == '1'">
          <v-icon left> mdi-form-select </v-icon>
          ฟอร์มเอกสาร
        </v-tab>
        <v-tab v-if="$store.getters['rule'] == '1'">
          <v-icon left> mdi-account </v-icon>
          ผู้ใช้
        </v-tab>
        <v-tab v-if="$store.getters['rule'] == '1'">
          <v-icon left> mdi-currency-usd </v-icon>
          เป้าการขาย
        </v-tab>
        <v-tab>
          <v-icon left> mdi-shopping </v-icon>
          สินค้า
        </v-tab>

        <!-- Profile -->
        <v-tab-item>
          <div class="container">
            <Profile />
          </div>
        </v-tab-item>

        <!-- Customer -->
        <v-tab-item>
          <div class="container">
            <div class="customer">
              <Viewcompany class="company" />
              <hr />
              <br />
              <Viewcustomer />
            </div>
          </div>
        </v-tab-item>

        <!-- Form -->
        <v-tab-item v-if="$store.getters['rule'] == '1'">
          <v-container>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Channel</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Viewchannel />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Status</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Viewstatus />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-tab-item>

        <!-- User -->
        <v-tab-item v-if="$store.getters['rule'] == '1'">
          <div class="container">
            <div class="customer">
              <ViewUsers class="company" />

              <h1>Manager</h1>
              <ViewManager />
            </div>
          </div>
        </v-tab-item>
        <!-- Sales Target -->
        <v-tab-item v-if="$store.getters['rule'] == '1'">
          <div class="container">
            <Saletarget />
          </div>
        </v-tab-item>

        <!-- Product -->
        <v-tab-item>
          <div class="container">
            <h1>Product</h1>
            <br />
            <Viewproduct />
            <br />
            <h1>Category</h1>
            <ViewCategory />
          </div>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import Viewcompany from "@/components/company/show_company.vue";
import Viewcustomer from "@/components/customer/view_customer.vue";
import ViewUsers from "@/components/users/view_users.vue";
import Viewproduct from "@/components/products/view_product.vue";

import Viewchannel from "@/components/setting/channel.vue";
import Viewstatus from "@/components/setting/status.vue";

import ViewCategory from "@/components/products/category.vue";
import ViewManager from "@/components/users/manager.vue";
import Saletarget from "@/components/setting/sales_target.vue";
import Profile from "@/components/profile.vue";

export default {
  name: "setting_page",
  components: {
    Viewcompany,
    Viewcustomer,
    ViewUsers,
    Viewproduct,
    Viewchannel,
    Viewstatus,
    ViewCategory,
    ViewManager,
    Profile,
    Saletarget,
  },
};
</script>

<style scoped>
.customer {
  margin: 20px;
}
.company {
  margin-bottom: 20px;
}
</style>
