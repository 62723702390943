<template>
  <v-data-table
    :headers="headers"
    :items="products"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>สินค้า</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              เพิ่มสินค้า
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">สินค้าใหม่</span>
            </v-card-title>

            <v-card-text>
              <AddProduct @dialog="set_dailog" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="Editdialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">แก้ไขลูกค้า</span>
            </v-card-title>

            <v-card-text>
              <EditProduct @dialog="set_editdailog" :items="editedItem" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="300px">
          <v-card>
            <v-card-title class="text-h5">คุณต้องการลบหรือไม่</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDelete"
                >ยกเลิก</v-btn
              >
              <v-btn color="red darken-1" text @click="deleteItemConfirm"
                >ลบ</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
    <!--***************************************** Table ************************************************* -->
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.name }}</td>
        <td>
          <v-img
            :src="item.image | imageUrl"
            lazy-src="https://picsum.photos/id/11/10/6"
            aspect-ratio="1"
            class="grey lighten-2"
            max-width="100"
            max-height="100"
          ></v-img>
        </td>
        <td>
          {{ item.category }}
        </td>
        <td>{{ item.detail | truncate(120) }}</td>
        <td>
          <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
          <span class="ma-1"></span>
          <v-icon @click="deleteItem(item)"> delete </v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import AddProduct from "@/components/products/add_product.vue";
import EditProduct from "@/components/products/edit_product.vue";
import product from "@/services/product";

export default {
  components: {
    AddProduct,
    EditProduct,
  },
  data: () => ({
    dialog: false,
    Editdialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Image", value: "image" },
      { text: "category", value: "category" },
      { text: "detail", value: "detail" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    products: [],
    deleteId: "",
    editedIndex: -1,
    editedItem: {},
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.get_products();
  },

  methods: {
    async get_products() {
      this.products = [];
      let result = await product.get_product();
      this.products = result.data.result;
    },

    set_dailog(data) {
      this.dialog = data;
      this.get_products();
    },

    set_editdailog(data) {
      this.Editdialog = data;
      this.get_products();
    },

    initialize() {
      this.products = [];
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = item;
      this.Editdialog = true;
    },

    deleteItem(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let result = await product.deleteProduct(this.deleteId);
      if (result.data.msg == "ok") {
        this.closeDelete();
      }
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.get_products();
    },
  },
};
</script>

<style></style>
