import httpClient from "@/services/httpClient";

const get_company = async () => {
  return httpClient.get("company");
};

const put_company = async (data) => {
  return httpClient.put("company", data);
};

const add_company = async (values) => {
  return httpClient.post("company", values);
};

const del_company = (id) => {
  return httpClient.delete("company" + `/id/${id}`);
};

export default { get_company, put_company, del_company, add_company };
