<template>
  <div>
    <apexchart
      el="app1"
      type="bar"
      :options="chartOptions"
      :series="series"
      max-width="500"
      height="350"
    ></apexchart>
  </div>
</template>

<script>
import dashboards from "@/services/dashboards";
export default {
  props: ["date","uid"],
  watch: {
    date(newVal) {
      this.reset();
      this.generate(newVal);
    },
  },
  data() {
    return {
      label: [],
      job_class: [],
      chartOptions: {
        theme: {
          palette: "palette1", 
        },
        chart: {
          id: "vuechart",
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "เจ้า";
          //   },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
      },
      series: [
        {
          name: "รวมทั้งหมด",
          data: [],
        },
      ],
    };
  },
  created() {
    this.generate(this.date, this.uid);
  },
  methods: {
    async generate(date, id) {
      let formData = new FormData();
      formData.append("date", date);
      formData.append("id", id);
      let { data } = await dashboards.get_counter_channel(formData);
      let product = data.result;
      this.chartOptions = {
          labels: product.map((prod) => prod.name),
          theme: {
            palette: "palette1",
          },
        };

        this.series = [
          {
            data: product.map((prod) => prod.c)
          },
        ];
        
    },
    reset() {
      this.label = [];
      this.job_class = [];
      this.chartOptions = {
        labels: [],
        colors: [],
      };
      this.series = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 200px;
    border-radius: 10px;
    height: 130px;
    padding: 30px;
    margin: 20px;
    line-height: 40px;
    color: white;
  }
}
</style>
