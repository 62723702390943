<template>
  <div>
    <!--************************************************ Navbar  ****************************************************-->
    <v-app-bar app color="#00ad5b" dark>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="$vuetify.breakpoint.mobile"
      ></v-app-bar-nav-icon>
      <v-btn icon @click="$router.back()" v-if="isShowBackBtn">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="!$vuetify.breakpoint.mobile" class="takeco-title"
        >Takeco Customer V.{{ version }}</v-toolbar-title
      >
      <v-spacer></v-spacer>

      <!-- <notifications group="foo" width="400px"/> -->
      <notifications group="foo" width="400px">
        <template slot="body" slot-scope="props">
          <v-alert
            shaped
            prominent
            type="info"
            dismissible
            icon="mdi-note-text"
          >
            <div>
              <!-- <a class="title">
                {{ props.item.title }}
              </a> -->
              <div v-html="props.item.text"></div>
              <p class="noti_time">
                {{ dayjs(props.item.data.time).fromNow() }}
              </p>
            </div>
          </v-alert>
        </template>
      </notifications>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="messages"
            :value="messages"
            color="red"
            overlap
            class="notification"
          >
            <v-icon v-bind="attrs" v-on="on"> mdi-bell</v-icon>
          </v-badge>
        </template>

        <v-card width="450" class="mx-auto">
          <v-toolbar color="info" dark dense rounded>
            <v-toolbar-title>รายการแจ้งเตือน</v-toolbar-title>
            <v-spacer></v-spacer>
            <p class="read_all" @click.prevent="read_all()">อ่านทั้งหมด</p>
          </v-toolbar>
          <div class="inbox">
            <v-list three-line v-if="items.length != 0">
              <template v-for="item in items">
                <v-divider></v-divider>
                <v-list-item
                  class="noti_item"
                  @click="noti_click(item.id, item.noti_id, item.type)"
                >
                  <v-badge
                    bordered
                    :color="item.color"
                    :icon="item.icon"
                    overlap
                    bottom
                    class="mr-4"
                  >
                    <v-avatar>
                      <img
                        v-if="item.img == null"
                        src="@/assets/avatar.png"
                        alt="profile"
                      />
                      <img v-else :src="item.img | profileUrl" alt="profile" />
                    </v-avatar>
                  </v-badge>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.firstname
                      }}{{ item.lastname }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ item.firstname }}{{ item.lastname }}

                      {{ item.description }}

                      คุณ{{ item.customer }} {{ item.company }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="dayjs(item.created).fromNow()"
                    ></v-list-item-action-text>

                    <v-icon v-if="item.readed == 1" color="white lighten-1">
                      mdi-circle-medium
                    </v-icon>

                    <v-icon v-else color="blue darken-3">
                      mdi-circle-medium
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </v-card>
      </v-menu>

      <span v-if="!$vuetify.breakpoint.mobile"
        >คุณ {{ $store.getters["username"] }}</span
      >
      <v-btn icon @click="onClickLogOff">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--************************************************ Menu  ****************************************************-->
    <v-navigation-drawer
      app
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      :temporary="$vuetify.breakpoint.smAndDown"
      dark
      src="@/assets/background_menu.jpg"
    >
      <router-link to="/" exact>
        <v-img src="@/assets/logo-header.png" alt="" width="100%" />
      </router-link>
      <v-list-item class="px-2" v-if="$vuetify.breakpoint.mobile">
        <v-list-item-avatar>
          <v-icon dark> mdi-account-circle </v-icon>
        </v-list-item-avatar>

        <v-list-item-title>{{ $store.getters["username"] }}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list shaped>
        <v-subheader>MENUS</v-subheader>
        <v-list-item-group v-model="selectedMenu" mandatory>
          <!-- *******************************************// For Admin ******************************************** -->
          <v-list-item
            v-if="$store.getters['rule'] == '1'"
            class="tile"
            v-for="([icon, text, route], i) in menus"
            :key="i"
            link
            @click="onClickMenu(route)"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            :value="true"
            prepend-icon="mdi-chart-pie"
            v-if="$store.getters['rule'] == '1'"
          >
            <template v-slot:activator>
              <v-list-item-title>รายงาน</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ยอดขาย</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="([title, icon, route], i) in admins"
                :key="i"
                link
                @click="onClickMenu(route)"
              >
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-group>

          <!--*************************************** // For User ********************************************-->
          <v-list-item
            v-if="$store.getters['rule'] == '3'"
            class="tile"
            v-for="([icon, text, route], i) in menus_user"
            :key="i"
            link
            @click="onClickMenu(route)"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            :value="true"
            prepend-icon="mdi-chart-pie"
            v-if="$store.getters['rule'] == '3'"
          >
            <template v-slot:activator>
              <v-list-item-title>รายงาน</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ยอดขาย</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="([title, icon, route], i) in users"
                :key="i"
                link
                @click="onClickMenu(route)"
              >
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-group>

          <!--*************************************** // For Manager ********************************************-->
          <v-list-item
            v-if="$store.getters['rule'] == '2'"
            class="tile"
            v-for="([icon, text, route], i) in menus_manager"
            :key="i"
            link
            @click="onClickMenu(route)"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            :value="true"
            prepend-icon="mdi-chart-pie"
            v-if="$store.getters['rule'] == '2'"
          >
            <template v-slot:activator>
              <v-list-item-title>รายงาน</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>ยอดขาย</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="([title, icon, route], i) in managers"
                :key="i"
                link
                @click="onClickMenu(route)"
              >
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list-item-group>
      </v-list>
      <template v-slot:append v-if="!$vuetify.breakpoint.mobile">
        <div class="pa-2">
          <v-btn block @click="onClickMenu('/setting')"
            ><v-icon>mdi-cog</v-icon> ตั้งค่า
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
const sub_paths = [
  "order-view",
  "manager-order-view",
  "add-data-manager",
  "setting",
  "newdata",
  "order",
  "user-Adddata",
  "user-order-view",
];
import noti from "@/services/notification";
// const someSound = require("@/assets/noti.mp3");
// let audioFile = new Audio(someSound);

export default {
  name: "Menu-main",
  props: [],
  data() {
    return {
      socket: {},
      items: [],
      messages: 0,
      drawer: null,
      selectedMenu: 0,
      admins: [
        ["ยอดขายรวม", "mdi-account-multiple-outline", "/report"],
        ["ยอดขายรายวัน", "mdi-cog-outline", "/dailyOrders"],
      ],
      managers: [
        ["ยอดขายรวม", "mdi-account-multiple-outline", "/target_report"],
        ["ยอดขายรายวัน", "mdi-cog-outline", "/target_daily"],
      ],
      users: [
        ["ยอดขายรวม", "mdi-account-multiple-outline", "/all_report"],
        ["ยอดขายรายวัน", "mdi-cog-outline", "/user_daily"],
      ],
      menus: [
        ["mdi-chart-areaspline", "ภาพรวม", "/"],
        ["mdi-file-plus", "เพิ่มข้อมูล", "/add-data"],
        ["mdi-file-document", "ดูข้อมูล", "/view"],
      ],
      menus_manager: [
        ["mdi-chart-areaspline", "ภาพรวม", "/dashboard/manager"],
        ["mdi-file-plus", "เพิ่มข้อมูล", "/add-data/manager"],
        ["mdi-file-document", "ดูข้อมูล", "/manager/view/"],
      ],
      menus_user: [
        ["mdi-chart-areaspline", "ภาพรวม", "/dashboard"],
        ["mdi-file-plus", "เพิ่มข้อมูล", "/user/add-data"],
        ["mdi-file-document", "ดูข้อมูล", "/user/view/"],
      ],
    };
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    isShowBackBtn() {
      return this.$route.matched.some(({ name }) => {
        let index = sub_paths.indexOf(name);
        return index != -1;
      });
    },
  },
  async created() {
    this.get_list_noti();
  },
  mounted() {
    if (this.$store.getters["rule"] == "1") {
      this.selectedMenu = this.menus.findIndex(
        (menu) => menu[2] == this.$route.path
      );
    }
    if (this.$store.getters["rule"] == "2") {
      this.selectedMenu = this.menus_manager.findIndex(
        (menu) => menu[2] == this.$route.path
      );
    }
    if (this.$store.getters["rule"] == "3") {
      this.selectedMenu = this.menus_user.findIndex(
        (menu) => menu[2] == this.$route.path
      );
    }
    this.$soketio.on("noti/" + localStorage.getItem("id"), (data) => {
      if (data.type != 3) {
        setTimeout(() => this.get_notification(data.id), 2000);
      } else {
        setTimeout(() => this.noti_del(data), 2000);
      }
    });
  },

  methods: {
    async read_all() {
      this.items = [];
      let formData = new FormData();
      formData.append("uid", localStorage.getItem("id"));
      let { data } = noti.update_noti_list(formData);
      console.log(data);
      this.messages = 0;
    },
    async get_notification(id) {
      let { data } = await noti.get_noti_now(id);
      this.$notify({
        group: "foo",
        type: "warn",
        duration: 5000,
        text: `<p style=" font-size: 15px;"><b style="font-weight: bold;">${data.result.firstname} ${data.result.lastname}</b> ${data.result.description} <strong style="font-weight: bold;">คุณ ${data.result.customer} ${data.result.company}</strong></p>`,
        data: { time: data.result.created },
      });
      this.messages += 1;

      this.items.unshift(data.result);
      this.$notification.show(
        "แจ้งเตือนจากระบบ",
        {
          body: `${data.result.firstname} ${data.result.lastname} ${data.result.description} คุณ${data.result.customer} ${data.result.company}`,
        },
        {}
      );
    },
    async get_list_noti() {
      let formData = new FormData();
      formData.append("uid", localStorage.getItem("id"));
      let { data } = await noti.get_noti_list(formData);
      this.items = data.result;
      this.messages = data.total;
    },
    noti_del(data) {
      this.$notify({
        group: "foo",
        type: "warn",
        duration: 5000,
        text: `<p style=" font-size: 15px;"><b style="font-weight: bold;">${data.actor.firstname} ${data.actor.lastname}</b> ได้ลบออร์เดอร์ ID ${data.result.id} <strong style="font-weight: bold;">คุณ${data.result.customer} ${data.result.company}</strong></p>`,
        data: { time: data.result.created },
      });
      this.messages += 1;
      this.items.unshift({
        color: "error",
        type: data.type,
        id: data.id,
        img: data.actor.img,
        company: data.result.company,
        customer: data.result.customer,
        description: "ได้ลบออร์เดอร์",
        firstname: data.actor.firstname,
        icon: "mdi-delete",
        lastname: data.actor.lastname,
      });
      this.$notification.show(
        "แจ้งเตือนจากระบบ",
        {
          body: `${data.actor.firstname}${data.actor.lastname} ได้ลบออร์เดอร์ ID ${data.result.id} คุณ${data.result.customer} ${data.result.company}`,
        },
        {}
      );
    },
    noti_click(id_order, noti_id, type) {
      if (type != 3) {
        this.$router.push(`/view/${id_order}`).catch((err) => {});
        this.items = this.items.filter((x) => x.noti_id !== noti_id);
        this.messages -= 1;
        let formData = new FormData();
        formData.append("id", noti_id);
        noti.put_noti_list(formData);
      } else {
        this.items = this.items.filter((x) => x.noti_id !== noti_id);
        this.messages -= 1;
      }
    },
    onClickMenu(link) {
      this.$router.push(link).catch((err) => {});
    },
    onClickLogOff() {
      this.$swal({
        title: "Are you sure?",
        text: "คุณต้องการออกจากระบบหรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่, ออกจากระบบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("doLogout");
        }
      });
      // this.$store.dispatch('doLogout')
    },
  },
  watch: {
    $route(to) {
      if (this.$store.getters["rule"] == "1") {
        let select = this.menus.findIndex((menu) => menu[2] == to.path);
        if (select != -1) {
          this.selectedMenu = this.menus.findIndex(
            (menu) => menu[2] == to.path
          );
        }
      }
      if (this.$store.getters["rule"] == "2") {
        let select = this.menus_manager.findIndex((menu) => menu[2] == to.path);
        if (select != -1) {
          this.selectedMenu = this.menus_manager.findIndex(
            (menu) => menu[2] == to.path
          );
        }
      }
      if (this.$store.getters["rule"] == "3") {
        let select = this.menus_user.findIndex((menu) => menu[2] == to.path);
        if (select != -1) {
          this.selectedMenu = this.menus_user.findIndex(
            (menu) => menu[2] == to.path
          );
        }
      }
    },
  },
};
</script>

<style>
.inbox {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
.text-bold {
  font-weight: bold;
}
.title {
  color: #fff !important;
}
.noti_time {
  font-size: 12px;
  padding-top: 5px;
}
.noti_item {
  cursor: pointer;
}
.v-list-item-group .v-list-item--active {
  color: #fff !important;
  font-weight: bold;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  opacity: 0.7;
}

.tile {
  color: #fff;
}
.tile:hover {
  background: green;
}
.tile:active {
  background: #05ab71;
  color: #05ab71;
}

.notification {
  margin-right: 15px;
  cursor: pointer;
}
.read_all {
  margin-top: 10px;
  cursor: pointer;
}
.read_all:hover {
  color: yellow;
}

.takeco-title {
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(to right, #ffffff 0%, #fffd9b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
