import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import login from "../views/login.vue";
import add_data from "../views/Add_data.vue";
import setting from "../views/setting.vue";
import order_view from "../views/orders";
import order_detail from "../views/orders/order-view.vue";
import auth from "@/services/auth";
import user_addData from "@/views/users/Add_data.vue";
import user_vieworder from "@/views/users/vieworder.vue";
import mobile from "@/views/mobile.vue";
import dashboard from "@/views/users/dashboard.vue";
import manager_dashboard from "@/views/manager/dashboard.vue";
import Add_data_manager from "@/views/manager/Add_data.vue";
import manager_view from "@/views/manager/vieworder.vue";
import report from "@/views/admin/report.vue";
import daily_orders from "@/views/admin/daily_orders.vue";
import target_report from "@/views/manager/report.vue";
import target_daily from "@/views/manager/daily_orders.vue";

import user_report from "@/views/users/report.vue";
import user_daily from "@/views/users/daily_orders.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    meta: { isSecured: true },
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/add-data",
    meta: { isSecured: true },
    name: "newdata",
    component: add_data,
  },
  {
    path: "/setting",
    meta: { isSecured: true },
    name: "setting",
    component: setting,
  },
  {
    path: "/view",
    meta: { isSecured: true },
    name: "order",
    component: order_view,
  },
  {
    path: "/view/:id",
    meta: { isSecured: true },
    name: "order-view",
    component: order_detail,
  },
  {
    path: "/user/add-data",
    meta: { isSecured: true },
    name: "user-Adddata",
    component: user_addData,
  },
  {
    path: "/user/view/",
    meta: { isSecured: true },
    name: "user-order-view",
    component: user_vieworder,
  },

  {
    path: "/manager/view/",
    meta: { isSecured: true },
    name: "manager-order-view",
    component: manager_view,
  },

  {
    path: "/dashboard",
    meta: { isSecured: true },
    name: "dashboard",
    component: dashboard,
  },

  {
    path: "/dashboard/manager",
    meta: { isSecured: true },
    name: "dashboard-manager",
    component: manager_dashboard,
  },

  {
    path: "/add-data/manager",
    meta: { isSecured: true },
    name: "add-data-manager",
    component: Add_data_manager,
  },
  {
    path: "/dailyOrders",
    meta: { isSecured: true },
    name: "daily_orders",
    component: daily_orders,
  },

  {
    path: "/report",
    meta: { isSecured: true },
    name: "report",
    component: report,
  },

  {
    path: "*",
    redirect: "/mobile", // page not found
  },

  {
    path: "/mobile",
    name: "mobile",
    component: mobile,
  },
  {
    path: "/target_report",
    name: "target_report",
    component: target_report,
  },
  {
    path: "/target_daily",
    name: "target_daily",
    component: target_daily,
  },
  {
    path: "/all_report",
    name: "all_report",
    component: user_report,
  },
  {
    path: "/user_daily",
    name: "user_daily",
    component: user_daily,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Router guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => (record.meta.isSecured ? true : false))) {
    // secure route
    if (auth.isLoggedIn()) {
      next();
    } else {
      next("/login");
    }
  } else {
    // unsecure route
    next();
  }
});

export default router;
