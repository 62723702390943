<template>
  <div class="main_target">
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <center>
          <div class="show_target">
            <h1 v-if="data != null">
              {{
                data.target
                  | currency("฿", 2, {
                    spaceBetweenAmountAndSymbol: true,
                  })
              }}
            </h1>
            <p>เป้าการขายเดือนนี้</p>
          </div>
        </center>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <center>
          <div class="show_target">
            <h1 v-if="data != null">
              {{
                data.actual
                  | currency("฿", 2, {
                    spaceBetweenAmountAndSymbol: true,
                  })
              }}
            </h1>
            <p>ยอดที่ทำได้</p>
          </div>
        </center>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import reports from "@/services/reports";
export default {
  mounted() {
    this.get_data();
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    async get_data() {
      let formData = new FormData();
      formData.append("date", localStorage.getItem('QueryDate'));
      formData.append("id", localStorage.getItem("id"));

      let { data } = await reports.user_actual_target(
        formData
      );
      if (data.msg == "ok") {
        this.data = data.result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main_target {
  margin: 30px;
}
.show_target {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 20px;
  width: 80%;
  height: 20vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 45px;
    color: rgb(81, 46, 217);
  }
}
@media screen and (max-width: 768px) {
  .main_target {
    margin: 0px;
    margin-top: 20px;
  }
  .show_target {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border-radius: 20px;
    width: 80%;
    height: 20vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 30px;
      color: rgb(81, 46, 217);
    }
  }
}
</style>
