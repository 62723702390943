<template lang="html">
  <v-main>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <GlobalAlert />
  </v-main>
</template>

<script lang="js">
import GlobalAlert from '@/components/global_alert.vue'
  export default  {
    name: 'Content-main',
    components: {
      GlobalAlert
    }

}
</script>

<style scoped lang="scss"></style>
