<template>
  <div>
    <apexchart
      el="app1"
      type="bar"
      :options="chartOptions"
      :series="series"
      height="350"
      v-if="date && date.length"
    ></apexchart>
  </div>
</template>

<script>
import dashboards from "@/services/dashboards";
export default {
  props: ["date"],
  watch: {
    date(newVal) {
      this.reset();
      this.generate(newVal)
    },
  },
  created() {
    // console.log(this.date)
    this.generate(this.date)
  },
  data() {
    return {
      label: [],
      job_class: [],
      chartOptions: {
        theme: {
          palette: "palette1",
        },
        chart: {
          id: "vuechart",
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          //   formatter: function (val) {
          //     return val + "เจ้า";
          //   },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
      },
      series: [
        {
          name: "รวมทั้งหมด",
          data: [],
        },
      ],
    };
  },

  methods: {
    // createChart(data) {
    //   let status_class = [];
    //   data.forEach((element) => {
    //     const myArray = element.product.split(",");
    //     myArray.forEach((element) => {
    //       status_class.push(element);
    //     });
    //   });

    //   let current = null;
    //   let cnt = 0;
    //   status_class.sort();
    //   for (let i = 0; i < status_class.length; i++) {
    //     if (status_class[i] != current) {
    //       if (cnt > 0) {
    //         this.label.push(cnt);
    //         this.job_class.push(current);
    //       }
    //       current = status_class[i];
    //       cnt = 1;
    //     } else {
    //       cnt++;
    //     }
    //   }
    //   if (cnt > 0) {
    //     this.label.push(cnt);
    //     this.job_class.push(current);
    //     this.chartOptions = {
    //       labels: this.job_class,
    //       theme: {
    //         palette: "palette1",
    //       },
    //     };

    //     this.series = [
    //       {
    //         data: this.label,
    //       },
    //     ];
    //   }
    // },
  async generate(date){
      let formData = new FormData();
        formData.append("date", date);
        formData.append("id", '');
        let { data } = await dashboards.get_counter_product(formData);
        let product = data.result;
        this.chartOptions = {
          labels: product.map(prod => prod.name),
          theme: {
            palette: "palette1",
          },
        };

        this.series = [
          {
            data: product.map(prod => prod.c),
          },
        ];
    },
    reset() {
      this.label = [];
      this.job_class = [];
      this.chartOptions = {
        labels: [],
        colors: [],
      };
      this.series = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 200px;
    border-radius: 10px;
    height: 130px;
    padding: 30px;
    margin: 20px;
    line-height: 40px;
    color: white;
  }
}
</style>
