<template>
  <v-data-table
    :headers="headers"
    :items="customers"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>รายชื่อลูกค้า</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              เพิ่มลูกค้า
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">ลูกค้าใหม่</span>
            </v-card-title>

            <v-card-text>
              <AddCustomer @dialog="set_dailog" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="Editdialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">แก้ไขลูกค้า</span>
            </v-card-title>

            <v-card-text>
              <EditCustomer @dialog="set_editdailog" :items="editedItem" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="300px">
          <v-card>
            <v-card-title class="text-h5">คุณต้องการลบหรือไม่</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDelete"
                >ยกเลิก</v-btn
              >
              <v-btn color="red darken-1" text @click="deleteItemConfirm"
                >ลบ</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
    <!--***************************************** Table ************************************************* -->
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.name }}</td>
        <td>
          {{ item.company_name }}
        </td>
        <td>
          {{ item.position }}
        </td>
        <td>{{ item.tel }}</td>
        <td>{{ item.email }}</td>
        <td>
          <v-icon class="mr-2" @click="editItem(item)"> edit </v-icon>
          <span class="ma-1"></span>
          <v-icon @click="deleteItem(item)"> delete </v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import AddCustomer from "@/components/customer/add_customer.vue";
import EditCustomer from "@/components/customer/edit_customer.vue";
import customer from "@/services/customers";
export default {
  components: {
    AddCustomer,
    EditCustomer,
  },
  data: () => ({
    dialog: false,
    Editdialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ชื่อ",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "บริษัท", value: "company_name" },
      { text: "ตำแหน่ง", value: "position" },
      { text: "เบอร์ติดต่อ", value: "tel" },
      { text: "อีเมล", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    customers: [],
    deleteId: "",
    editedIndex: -1,
    editedItem: {},
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.get_customer();
  },

  methods: {
    async get_customer() {
      this.customers = [];
      let result = await customer.get_customer();
      this.customers = result.data.result;
    },

    set_dailog(data) {
      this.dialog = data;
      this.get_customer();
    },

    set_editdailog(data) {
      this.Editdialog = data;
      this.get_customer();
    },

    initialize() {
      this.customers = [];
    },

    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = item;
      this.Editdialog = true;
    },

    deleteItem(item) {
      this.deleteId = item.id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let result = await customer.del_customer(this.deleteId);
      if (result.data.msg == "ok") {
        this.closeDelete();
      }
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.get_customer();
    },
  },
};
</script>

<style></style>
