import httpClient from "@/services/httpClient";

const get_actual_target = async (data) => {
  return httpClient.post("sum_targets", data);
};

const get_all_report_target = async (data) => {
  return httpClient.post("report_target", data);
};

const get_bymonth_report_target = async (id) => {
  return httpClient.get("report_target" + `/id/${id}`);
};

const report_daily = async (data) => {
  return httpClient.post("report_daily_product", data);
};

// *********************** Manager ********************************

const report_target_manager = async (id) => {
  return httpClient.get("report_target" + `/manager/${id}`);
};

const get_all_target_report = async (id) => {
  return httpClient.get("target_report" + `/manager/${id}`);
};

const get_bymonth_target = async (id) => {
  return httpClient.get("target_report" + `/id/${id}`);
};

// *********************** Users ********************************

const user_actual_target = async (data) => {
  return httpClient.post("users_targets",data);
};

const user_all_report = async (data) => {
  return httpClient.post("user_daily_product", data);
};

export default {
  get_actual_target,
  get_all_report_target,
  get_bymonth_report_target,
  report_daily,
  report_target_manager,
  get_all_target_report,
  get_bymonth_target,
  user_actual_target,
  user_all_report,
};
