<template>
  <div class="background-view">
    <v-container>
      <!-- /**************************** Section 1 **********************/ -->
      <Counters :orders="orders" v-if="!$vuetify.breakpoint.mobile" />
      <br />
      <div class="chart">
        <Targets :key="componentKey" />
      </div>

      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <div class="chart">
              <h2>สถานะงาน</h2>
              <Pie_chart :orders="orders" />
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <div class="chart">
              <h2>งานใหม่เพิ่มหรืออัพเดตล่าสุด</h2>
              <Worktable
                :users="seller"
                @renew="getsales"
                :key="componentKey"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- /**************************** Section 2  สินค้าที่ขาย**********************/ -->
      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <div class="chart">
              <h2>งานที่ขาดการอัพเดต</h2>
              <Worklate :users="seller" @renew="getsales" :key="componentKey" />
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <div class="chart">
              <h2>สินค้าที่ขาย</h2>
              <Bar_product :orders="orders" />
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- /**************************** Section 3 พนักงานขาย**********************/ -->
      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <div class="chart">
              <h2>พนักงานขาย</h2>
              <Pie_Saleman :orders="orders" />
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <div class="chart">
              <div>
                <h2>ช่องทางการขาย</h2>
              </div>
              <Channel_report :orders="orders" />
            </div>
          </v-col>
        </v-row>
      </div>
      <br />

      <!-- /**************************** Section 4 ช่องทางการขาย **********************/ -->
      <div class="chart">
        <h2>รายงานประจำเดือน {{ month_name(dayjs().month()) }}</h2>
        <br />
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <Month_report :users="seller" />
          </v-col>
        </v-row>
      </div>

      <!-- /**************************** Section 4 พนักงานขาย **********************/ -->
      <!-- <div class="chart_data">
        <v-row>
          <v-col cols="12" md="2" sm="12">
            <div class="chart">
              <h2>พนักงานขาย</h2>
              <v-list dense>
                <v-subheader>REPORTS</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in seller"
                    :key="i"
                    @click="get_orderBysale(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.firstname + " " + item.lastname
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>

          <v-col cols="12" md="10" sm="12">
            <div class="chart">
              <br />
              <v-row>
                <v-col cols="12" md="5" sm="12">
                  <Pie_chart :orders="orderBysale" />
                </v-col>
                <v-col cols="12" md="7" sm="12">
                  <Sale_report :orders="orderBysale" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import Counters from "@/components/dashboard/counter.vue";
import Pie_chart from "@/components/dashboard/pie_chart.vue";
import Pie_Saleman from "@/components/dashboard/pie_chart_user.vue";
import Worktable from "@/components/dashboard/manager/table_work.vue";
import Month_report from "@/components/dashboard/manager/mounth_report.vue";
import Sale_report from "@/components/dashboard/sale_report.vue";
import Channel_report from "@/components/dashboard/channel_report.vue";
import dashboards from "@/services/dashboards";
import Bar_product from "@/components/dashboard/admin/bar_product.vue";
import Worklate from "@/components/dashboard/manager/table_work_late.vue";
import users from "@/services/users";
import Targets from "@/components/dashboard/manager/target.vue";

export default {
  name: "home",
  components: {
    Counters,
    Pie_chart,
    Worktable,
    Pie_Saleman,
    Month_report,
    Sale_report,
    Channel_report,
    Worklate,
    Bar_product,
    Targets,
  },
  data() {
    return {
      orders: [],
      seller: [],
      selectedItem: 0,
      orderBysale: [],
      componentKey: 0,
    };
  },
  created() {
    if (localStorage.getItem("rule") != "2") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.componentKey += 1;
    this.getsales();
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    async get_order(users) {
      let formData = new FormData();
      formData.append("user", JSON.stringify(users));
      let result = await dashboards.get_order_ofmanager(formData);
      this.orders = result.data.result;
      if (this.componentKey != 1) {
        console.log(this.componentKey);
        this.forceRerender();
      }
    },
    async get_orderBysale(id) {
      let result = await dashboards.get_orderBysale(id);
      this.orderBysale = result.data.result;
    },
    month_name(index) {
      if (index == "0") return "มกราคม (JANUARY)";
      else if (index == "1") return "กุมภาพันธ์ (FEBRUARY)";
      else if (index == "2") return "มีนาคม (MARCH)";
      else if (index == "3") return "เมษายน (APRIL)";
      else if (index == "4") return "พฤษภาคม (MAY)";
      else if (index == "5") return "มิถุนายน (JUNE)";
      else if (index == "6") return "กรกฎาคม (JULY)";
      else if (index == "7") return "สิงหาคม (AUGUST)";
      else if (index == "8") return "กันยายน (SEPTEMBER)";
      else if (index == "9") return "ตุลาคม (OCTOBER)";
      else if (index == "10") return "พฤศจิกายน (NOVEMBER)";
      else return "ธันวาคม (DECEMBER)";
    },
    async getsales() {
      let result = await users.get_userOfmanager(localStorage.getItem("id"));
      this.seller = result.data.result;
      this.get_order(result.data.result);
      this.get_orderBysale(this.seller[0].id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item-group .v-list-item--active {
  color: #ff3c3c !important;
  font-weight: bold;
}
.background-view {
  height: 100%;
  background-color: rgb(248, 248, 248);
}
.container {
  padding: 50px;
}
.chart_data {
  margin-top: 30px;
}
.chart {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}
</style>
