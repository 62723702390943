<template>
  <div>
    <v-dialog v-model="dialog_comment" max-width="800">
      <v-card>
        <v-card-title class="text-h5"> Comment </v-card-title>
        <div class="ma-5 myBox" v-if="comment_order.length != 0">
          <div
            class="comment pa-5 ma-3"
            v-for="item in comment_order"
            :key="item.index"
          >
            <v-row class="pa-4 comment-text">
              <p>{{ item.detail }}</p>
            </v-row>
            <v-row class="comment_detail">
              <v-col cols="12" md="8" sm="6">
                <div v-if="item.file != null">
                  <v-chip
                    class="mr-1 mb-1"
                    close
                    color="primary"
                    text-color="white"
                    small
                    v-for="data in item.file.split(',')"
                    :key="data.index"
                    close-icon="mdi-download-circle"
                    @click:close="download_comment(data)"
                  >
                    {{ data | truncate(20) }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <p>
                  <!-- {{ dayjs(item.created).format("DD-MM-BBBB") }} |
                    {{ dayjs(item.created).format("HH:mm:ss") }} -->
                  {{ dayjs(item.created).fromNow() }}
                </p>
                <br />
                <h4>ชื่อ :</h4>
                <p>{{ item.firstname }}</p>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="ma-5">
          <v-row class="formUpdate">
            <v-form ref="form">
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  :rules="[rules.required, rules.counter]"
                  outlined
                  counter
                  v-model="comments.detail"
                  label="detail"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-file-input
                  show-size
                  chips
                  multiple
                  label="File input"
                  v-model="comments.files"
                ></v-file-input>
              </v-col>
            </v-form>
          </v-row>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="dialog_comment = false">
              ยกเลิก
            </v-btn> -->
          <v-btn
            color="success"
            class="mb-2 white--text"
            @click="comment_add()"
          >
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import order from "@/services/orders";
import axios from "axios";
import { orderUrl } from "@/services/constants";

export default {
  props: ["orders", "dialogs"],
  watch: {
    orders: function () {
      this.comment();
    },
  },
  computed: {
    order_ids() {
      return this.orders;
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        size: (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
        counter: (value) => value.length <= 200 || "Minimum 10 characters",
      },
      dialog_comment: false,
      comments: {
        detail: "",
        files: [],
      },
      comment_order: [],
    };
  },
  methods: {
    download_comment(file) {
      axios
        .get(`${orderUrl}/download/file_cmoment/${file}`, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    async get_comment(id) {
      let result = await order.get_ordercomment(id);
      this.comment_order = await result.data.result;
      this.dialog_comment = true;
    },

    async comment() {
      this.comment_id = this.order_ids;
      this.get_comment(this.order_ids);
    },

    async comment_add() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();

        formData.append("detail", this.comments.detail);
        formData.append("id", this.comment_id);
        formData.append("user", localStorage.getItem("id"));

        if (this.comments.files.length !== 0) {
          for (let i = 0; i < this.comments.files.length; i++) {
            let file = this.comments.files[i];
            formData.append("files[" + i + "]", file);
          }
        }
        let result = await order.update_comment(formData);
        if (result.data.msg == "ok") {
          // this.dialog_comment = false;
          this.get_comment(this.comment_id);
          this.comments = {
            detail: "",
            files: [],
          };
        } else if (result.data.msg == "error") {
          alert(result.data.result);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formUpdate {
  display: grid;
}
.comment_detail h4 {
  font-size: 13px;
  display: inline;
}
.comment_detail p {
  font-size: 13px;
  display: inline;
}
.comment {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 20px;
}

.comment-text {
  background: #eceeef;
  border-radius: 10px;
}
.myBox {
  border: none;
  padding: 5px;
  width: 97%;
  height: 450px;
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: #1793c5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4c5cd0;
}
</style>
