<template>
  <div class="mainbox">
    <v-dialog
      ref="dialog"
      v-model="modal"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="datetime"
          label="ตั้งค่าวันที่"
          prepend-icon="mdi-calendar"
          readonly 
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="datetime" type="month" scrollable locale="th">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> ปิด </v-btn>
        <v-btn text color="primary" @click="save(datetime)">
          ตั้งค่า
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datetime: this.dayjs(localStorage.getItem('QueryDate')).format("YYYY-MM"),
      modal: false,
    };
  },
  created() {
    if (this.datetime == null || this.datetime =="Invalid Date") {
      localStorage.setItem('QueryDate', this.dayjs().format("YYYYMM"));
      this.datetime = this.dayjs().format("YYYY-MM");
      this.$router.go()
    }else{
      this.datetime = this.dayjs(localStorage.getItem('QueryDate')).format("YYYY-MM");
    }
  },
  methods: {
    save(date){
      localStorage.setItem('QueryDate', this.dayjs(date).format("YYYYMM"));
      this.datetime = date;
      this.modal = false;
      this.$emit('dateChange', this.dayjs(date).format("YYYYMM"));
    }
  },
};
</script>

<style lang="scss" scoped>
.mainbox{
 margin: 20px;   
}    
.v-text-field{
      width: 400px;
}
</style>
