<template>
  <div class="background-view">
    <v-container>
      <!-- /**************************** Counter **********************/ -->
      <div class="counter" v-if="totals && totals.length && !$vuetify.breakpoint.mobile" >
        <div v-for="item in totals" :key="item.index">
          <div class="element-counter">
            <h1>{{ item.total }}</h1>
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>

      <div class="Setdate">
        <Dateset @dateChange="dateChange"/>
      </div>
      
      <!-- /**************************** Counter **********************/ -->
      <Target :key="componentKey" />
      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <div class="chart">
              <h2>สถานะงาน</h2>
              <Pie_chart :data = "totals"   />
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <div class="chart">
              <h2>งานใหม่เพิ่มหรืออัพเดตวันนี้</h2>
              <Worktable @renew="get_order" :key="componentKey" />
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- /**************************** Section 2  สินค้าที่ขาย**********************/ -->
      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <div class="chart">
              <h2>งานที่ขาดการอัพเดต</h2>
              <Worklate @renew="get_order" :key="componentKey" />
            </div>
          </v-col>
          <v-col cols="12" md="12" sm="12" v-if="!$vuetify.breakpoint.mobile">
            <div class="chart">
              <h2>สินค้าที่ขาย</h2>
              <Bar_product :date="datetime" :key="componentKey"/>
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- /**************************** Section 3 พนักงานขาย**********************/ -->
      <div class="chart_data">
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <div class="chart">
              <h2>พนักงานขาย</h2>
              <Pie_Saleman  :date="datetime"/>
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12">
            <div class="chart">
              <div>
                <h2>ช่องทางการขาย</h2>
              </div>
              <Channel_report  :date="datetime" :uid="uuid"/>
            </div>
          </v-col>
        </v-row>
      </div>
      <br />

      <!-- /**************************** Section 4 ช่องทางการขาย **********************/ -->
      <div class="chart">
        <h2>รายงานประจำเดือน</h2>
        <br />
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <Month_report :date="datetime" :uid="uuid"/>
          </v-col>
        </v-row>
      </div>

      <!-- /**************************** Section 4 พนักงานขาย **********************/ -->
      <!-- <div class="chart_data">
        <v-row>
          <v-col cols="12" md="2" sm="12">
            <div class="chart">
              <h2>พนักงานขาย</h2>
              <v-list dense>
                <v-subheader>REPORTS</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in seller"
                    :key="i"
                    @click="get_orderBysale(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.firstname + " " + item.lastname
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>

          <v-col cols="12" md="10" sm="12">
            <div class="chart">
              <h2>รายงานพนักงานขาย</h2>
              <br />
              <v-row v-if="orderBysale.length != 0">
                <v-col cols="12" md="5" sm="12">
                  <Pie_chart :orders="orderBysale" />
                </v-col>
                <v-col cols="12" md="7" sm="12">
                  <Sale_report :orders="orderBysale" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="12" sm="12">
                  <p>ไม่มีข้อมูล</p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import Counters from "@/components/dashboard/counter.vue";
import Pie_chart from "@/components/dashboard/pie_chart.vue";
import Pie_Saleman from "@/components/dashboard/pie_chart_user.vue";
import Worktable from "@/components/dashboard/admin/table_work.vue";
import Month_report from "@/components/dashboard/mounth_report.vue";
import Sale_report from "@/components/dashboard/sale_report.vue";
import Channel_report from "@/components/dashboard/channel_report.vue";
import dashboards from "@/services/dashboards";
import Bar_product from "@/components/dashboard/admin/bar_product.vue";
import Worklate from "@/components/dashboard/admin/table_work_late.vue";
import Target from "@/components/dashboard/admin/target.vue";
import Dateset from '@/components/set_date.vue'

export default {
  name: "home",
  components: {
    Counters,
    Pie_chart,
    Worktable,
    Pie_Saleman,
    Month_report,
    Sale_report,
    Channel_report,
    Worklate,
    Bar_product,
    Target,
    Dateset
  },
  data() {
    return {
      seller: [],
      totals: [],
      selectedItem: 0,
      orderBysale: [0],
      componentKey: 0,
      datetime: null,
      uuid: "",
    };
  },
  created() {
    if (localStorage.getItem("rule") == "3") {
      this.$router.push("/dashboard");
    }
    if (localStorage.getItem("rule") == "2") {
      this.$router.push("/dashboard/manager");
    }
    this.datetime = localStorage.getItem('QueryDate');
    this.get_data();
  },
  mounted() {
    
    // this.componentKey += 1;
    // this.getsales();
    
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    async get_data() {
      try {
        let formData = new FormData();
        formData.append("date", this.datetime);
        formData.append("id", "");
        let { data } = await dashboards.get_counter_order(formData);
        this.totals = data.result;
        if (this.componentKey != 1) {
        this.forceRerender();
      }
      } catch (error) {
        console.log(error)
      }
    },
    async get_order() {
      if (this.componentKey != 1) {
        this.forceRerender();
      }
    },
    // async get_orderBysale(id) {
    //   let formData = new FormData();
    //   formData.append("date", this.datetime);
    //   formData.append("date", id);
    //   let {data} = await dashboards.get_orderBysale(formData);
    //   this.orderBysale = data.result;
    // },
    // async getsales() {
    //   let result = await setting.get_sales();
    //   this.seller = result.data.result;
    //   this.get_orderBysale(this.seller[0].id);
    // },
  async dateChange(data){
      // console.log(data);
      // this.datetime = data
      // this.get_data();
      this.$router.go();
  }
  },
};
</script>

<style lang="scss" scoped>
.v-list-item-group .v-list-item--active {
  color: #ff3c3c !important;
  font-weight: bold;
}
.background-view {
  height: 100%;
  background-color: rgb(248, 248, 248);
}
.container {
  padding: 50px;
}
.chart_data {
  margin-top: 30px;
}
.chart {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}
.counter {
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.element-counter {
  background-color: #68b7ff;
  cursor: pointer;
  width: 20vh;
  height: 20vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 45px;
    color: rgb(255, 255, 255);
  }
}

@media screen and (max-width: 736px) {
  .counter {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px;
  }
}

@media screen and (max-width: 736px) {
  .counter {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 26px;
  }
}
.Setdate{
  display: flex;
  flex-direction:row;
  justify-content:center;
}
</style>
