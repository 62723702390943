<template>
  <div class="text-center">
    <v-snackbar
      v-model="$store.getters['alerts'].snackbar"
      :timeout="3500"
      :value="true"
      absolute
      right
      shaped
      top
      :color="$store.getters['alerts'].color"
    >
      {{ $store.getters["alerts"].text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "snackbar_alert",
};
</script>
