<template>
  <div class="container">
    <h1>ยอดขายรายวัน</h1>
    <div class="menu_query">
      <v-row>
        <v-col cols="12" md="4" sm="12"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="search_data.date"
                label="เลือกวันที่"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search_data.date"
              no-title
              scrollable
              locale="th"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> ปิด </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                เลือก
              </v-btn>
            </v-date-picker>
          </v-menu></v-col
        >
        <v-col cols="12" md="4" sm="12">
          <v-btn tile color="success" @click.prevent="find_data()">
            <v-icon left> mdi-magnify </v-icon>
            ค้นหา
          </v-btn></v-col
        >
      </v-row>
    </div>
    <div class="empty_data" v-if="daily_report == null">
      <h1>ยังไม่มีข้อมูล</h1>
    </div>
    <div class="table_data" v-if="daily_report != null" id="printMe">
      <table style="width: 100%">
        <tr>
          <th colspan="2" class="table_title">
            <h3>
              ตารางบันทึกออร์เดอร์รายวัน(ยอดจองเดือน {{ dateFormat.date }}
              {{ dateFormat.year }})
            </h3>
            <h3>
              Sale {{ daily_report.target.firstname }} ( เป้า
              {{
                daily_report.target.month
                  | currency("฿", 0, {
                    spaceBetweenAmountAndSymbol: true,
                  })
              }}
              )
            </h3>
          </th>
        </tr>
        <tr>
          <th style="width: 5%">ลำดับ</th>
          <th style="width: 60%; height: 80px">รายชื่อลูกค้า</th>
          <th style="background-color: #f47c7c">สินค้า</th>
          <th style="background-color: #76ba99">ลับคม</th>
          <th>รวม</th>
        </tr>
        <tr
          v-for="item in daily_report.result"
          :key="item.index"
          style="height: 30px"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.prod | currency("", 0) }}</td>
          <td>{{ item.reg | currency("", 0) }}</td>
          <td>{{ item.total | currency("", 0) }}</td>
        </tr>
        <tr>
          <td colspan="5" style="height: 30px"></td>
        </tr>
        <tr>
          <td colspan="2"><h3>รวม</h3></td>
          <td>{{ daily_report.product | currency("", 0) }}</td>
          <td>{{ daily_report.regirnd | currency("", 0) }}</td>
          <td style="height: 30px; background-color: #f2df3a">
            {{ daily_report.month | currency("", 0) }}
          </td>
        </tr>
        <tr>
          <td style="border-collapse: collapse"></td>
          <td style="border-collapse: collapse"></td>
          <td colspan="2" style="background-color: #a6d1e6">
            {{
              (daily_report.product + daily_report.regirnd) | currency("", 0)
            }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td
            style="background-color: #f47c7c"
            v-if="
              daily_report.month <
              daily_report.target.month
            "
          >
            <p>
              -
              {{
                (daily_report.target.month - daily_report.month)
                  | currency("", 0)
              }}
            </p>
            <!-- //////////////////////////////// -->
            <!-- <p
              v-if="
                daily_report.target.month - daily_report.month >
                daily_report.target.month
              "
            >
              +
              {{
                (daily_report.target.month - daily_report.month)
                  | currency("", 0)
              }}
            </p> -->
          </td>
          <td
            style="background-color: #5eaaa8"
            v-if="
              daily_report.month >
              daily_report.target.month
            "
          >
            <p
              v-if="
                daily_report.month >
                daily_report.target.month
              "
            >
              +
              {{
                (daily_report.month-daily_report.target.month)
                  | currency("", 0)
              }}
            </p>
          </td>
        </tr>
      </table>
    </div>

    <div>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        absolute
        right
        shaped
        color="red accent-2"
        top
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <br />
    <div class="btn_print" v-if="daily_report != null">
      <v-btn rounded color="primary" large dark @click="print()">
        <v-icon left> mdi-printer </v-icon> พิมพ์
      </v-btn>
    </div>
  </div>
</template>

<script>
import users from "@/services/users";
import report from "@/services/reports";

export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      seller: [],
      search_data: {
        date: "",
      },
      validate: ["user", "date"],
      snackbar: false,
      text: "My timeout is set to 2000.",
      timeout: 2500,
      daily_report: null,
      month: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      dateFormat: {
        date: "",
        year: "",
      },
    };
  },
  methods: {
    validated() {
      let check = true;
      this.validate.forEach((element) => {
        if (this.search_data[element] == "") {
          check = false;
          this.snackbar = true;
          this.text = "กรุณาใส่ข้อมูลให้ครบ";
        }
      });
      return check;
    },
    async dateFormater() {
      let time = this.search_data.date.split("-");
      this.dateFormat.year = parseInt(time[0]) + 543;
      this.dateFormat.date = this.month[parseInt(time[1] - 1)];
    },
    async find_data() {
      if (this.validated()) {
        await this.dateFormater();
        let formData = new FormData();
        formData.append("user", localStorage.getItem("id"));
        formData.append("date", this.search_data.date);

        let { data } = await report.report_daily(formData);
        if (data.result.length != 0) {
          this.daily_report = data;
        } else {
          this.daily_report = null;
        }
      }
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printMe").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "width=" + screen.availWidth + ",height=" + screen.availHeight
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();

      WinPrint.focus();
      WinPrint.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300&display=swap");
.container {
  padding: 30px;
}
.table_data {
  margin-top: 20px;
  font-weight: bold;
}

.menu_query {
  margin-top: 20px;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
@media print {
  @page {
    margin: 2cm;
    size: A4 landscape; // หรือเช่น 21cm 29.7cm landscape
  }
  html,
  body {
    font-family: "Kanit", sans-serif;
    scroll-behavior: smooth;
  }
}
.table_title {
  height: 80px;
  background-color: rgb(214, 238, 238);
}

.btn_print {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

* {
  -webkit-print-color-adjust: exact !important;
}
.empty_data {
  margin: 30px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
</style>
